<div id="layout-wrapper">
  <app-topbar> </app-topbar>
  <!-- <app-sidebar></app-sidebar> -->
  <!-- ============================================================== -->
  <!-- Start right Content here -->
  <!-- ============================================================== -->
  <div class="">
    <div class="page-content-Cs">
      <router-outlet></router-outlet>
    </div>
    <app-footer-Cs
      *ngIf="CsFooterFlag"
      (CsMenuButtonClicked)="onToggleCsMenu()"
      (settingsButtonClicked)="onSettingsButtonClicked()"
    ></app-footer-Cs>
  </div>
  <app-rightsidebar></app-rightsidebar>
</div>
<!-- END layout-wrapper -->
