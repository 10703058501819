<div class="container-fluid">
  <app-pagetitle title="{{'Thống kê' | translate}}"></app-pagetitle>
  <div class="card px-4 pt-4 flex-md-row">
    <div class="container">
      <!-- First row -->
      <div class="row mb-4 d-flex justify-content-between">
        <div class="col-md-4">
          <div class="card border border-warning">
            <div class="card-body p-1">
              <div class="d-flex row mb-2">
                <div class="col-md-2 pt-3">
                  <i class="ri-store-3-line display-5 text-success"></i>
                  <!-- <img
                    src="../../../assets/images/store.png"
                    style="width: 3.5rem;"
                    alt="store"
                  /> -->
                </div>
                <div class="col-md-10 mb-1">
                  <h6
                    class="col mt-4 text-center text-secondary"
                    style="font-size: 150%;"
                    *ngIf="data"
                  >
                    {{ data?.totalDeviceStock }}
                  </h6>
                  <h5 class="text-lg-center">Tổng số thiết bị nhập kho</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card border border-warning">
            <div class="card-body p-1">
              <div class="d-flex row mb-2">
                <div class="col-md-3 pt-3">
                  <img
                    src="../../../assets/images/active.png"
                    style="width: 3.5rem;"
                    alt="Icon Thiết Bị"
                    class="display-5"
                  />
                </div>
                <div class="col-md-9 mb-1">
                  <h6
                    class="col mt-4 text-center text-dark"
                    style="font-size: 150%;"
                    *ngIf="data"
                  >
                    {{ data?.totalDeviceDelivered }}
                  </h6>
                  <h5 class="text-lg-center">Số thiết bị đã giao cho khách</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card border border-warning">
            <div class="card-body p-1">
              <div class="d-flex row mb-2">
                <div class="col-md-2 pt-3">
                  <img
                    src="../../../assets/images/qr_code_payment_icon_181835.png"
                    style="width: 3.5rem;"
                    alt="image"
                    class="display-5"
                  />
                </div>
                <div class="col-md-10 mb-1">
                  <h5 class="col mt-4 text-center text-info" *ngIf="data">
                    {{ data?.inventoryDevice }}
                  </h5>
                  <h5 class="text-lg-center">Số thiết bị tồn kho</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Second row -->
      <div class="row mb-4">
        <div class="col-md-4">
          <div class="card border border-warning">
            <div class="card-body p-1">
              <div class="d-flex row mb-2">
                <div class="col-md-2 pt-3">
                  <i
                    class="ri-shopping-basket-2-line text-success display-5"
                  ></i>
                </div>
                <div class="col-md-10 mb-1">
                  <h5
                    class="col mt-4 text-center ml-4 text-success"
                    *ngIf="data"
                  >
                    {{ data?.totalCustomerOrder }}
                  </h5>
                  <h5 class="text-center">Tổng số đơn hàng</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card border border-warning">
            <div class="card-body p-1">
              <div class="d-flex row mb-2">
                <div class="col-md-2 pt-3">
                  <i class="ri-check-double-line text-success display-5"></i>
                  <!-- <img
                    src="../../../assets/images/store.png"
                    style="width: 3.5rem;"
                    alt="store"
                  /> -->
                </div>
                <div class="col-md-10 mb-1">
                  <h6
                    class="col mt-4 text-center text-secondary"
                    style="font-size: 150%;"
                    *ngIf="data"
                  >
                    {{ data?.totalOrderDelivered }}
                  </h6>
                  <h5 class="text-lg-center">Tổng số đơn đã giao</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card border border-warning">
            <div class="card-body p-1">
              <div class="d-flex row mb-2">
                <div class="col-md-3 pt-3">
                  <span
                    class="mdi mdi-account-clock text-success display-5"
                  ></span>
                  <!-- <img
                    src="../../../assets/images/qr_code_payment_icon_181835.png"
                    style="width: 3.5rem;"
                    alt="Icon Thiết Bị"
                  /> -->
                </div>
                <div class="col-md-9 mb-1">
                  <h6
                    class="col mt-4 text-center text-dark"
                    style="font-size: 150%;"
                    *ngIf="data"
                  >
                    {{ data?.totalOrderProcessing }}
                  </h6>
                  <h5 class="text-lg-center">Tổng đơn đang xử lý</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Third row -->
      <div class="row mb-4">
        <div class="col-md-4">
          <div class="card border border-warning">
            <div class="card-body p-1">
              <div class="d-flex row mb-2">
                <div class="col-md-2 pt-3">
                  <i class="ri-group-line display-5 text-success"></i>
                  <!-- <img
                      src="../../../assets/images/active.png"
                      style="width: 3.5rem;"
                      alt="image"
                      class="display-5"
  
                    /> -->
                </div>
                <div class="col-md-10 mb-1">
                  <h5 class="col mt-4 text-center text-info" *ngIf="data">
                    {{ data?.totalCustomer }}
                  </h5>
                  <h5 class="text-lg-center">Tổng số khách hàng</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card border border-warning">
            <div class="card-body p-1">
              <div class="d-flex row mb-2">
                <div class="col-md-2 pt-3">
                  <i class="ri-bar-chart-fill display-5 text-success"></i>
                  <!-- <span
                    class="mdi mdi-chart-line text-success"
                    style="font-size: 48px;"
                  ></span> -->
                </div>
                <div class="col-md-10 mb-1">
                  <h5
                    class="col mt-4 text-center ml-4 text-success"
                    *ngIf="data"
                  >
                    {{ data?.totalTransaction }}
                  </h5>
                  <h5 class="text-center">Tổng số giao dịch</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card border border-warning">
            <div class="card-body p-1">
              <div class="d-flex row mb-2">
                <div class="col-md-2 pt-3">
                  <span
                    class="mdi mdi-chart-line text-success"
                    style="font-size: 48px;"
                  ></span>
                </div>
                <div class="col-md-10 mb-1">
                  <h5
                    class="col mt-4 text-center ml-4 text-success"
                    *ngIf="data"
                  >
                    {{ data?.totalMoney | number: '1.0-0' }}
                    <sup><ins>đ</ins></sup>
                  </h5>
                  <h5 class="text-center">Tổng số tiền giao dịch</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-12">
      <!-- <div class="row">
              <div class="col-md-3" *ngFor="let item of listType">
                  <app-stat2 [title]="item.name" [color]="'#5ac918'" [value]="item.total"></app-stat2>
              </div>
              <div class="col-md-4">
                  <app-stat [title]="'Nhân viên'" [color]="'#e8851e'" [value]="5" [icon]="'fas fa-user'"></app-stat>
              </div>
              <div class="col-md-4">
                  <app-stat [title]="'Đối tác'" [color]="'#d22e0e'" [value]="2" [icon]="'fas fa-user-friends'"></app-stat>
              </div>
          </div> -->
      <!-- <div class="mt-3">
              <h4>Đơn hàng</h4>
              <div class="row mt-3" *ngIf="bookingStatus">
                  <div class="col-md-3">
                      <app-stat2 [title]="'Huỷ bỏ'" [value]="(bookingStatus.canceled || 0)" [color]="'#ff432e'" [icon]="'mdi mdi-close-thick'"></app-stat2>
                  </div>
                  <div class="col-md-3">
                      <app-stat2 [title]="'Chưa thực hiện'" [value]="(bookingStatus.waiting)" [color]="'#4f4f4f'" [icon]="'mdi mdi-pause-circle'"></app-stat2>
                  </div>
                  <div class="col-md-3">
                      <app-stat2 [title]="'Đang thực hiện'" [value]="(bookingStatus.on_doing)" [color]="'#3937ff'" [icon]="'mdi mdi-content-save-move'"></app-stat2>
                  </div>
                  <div class="col-md-3">
                      <app-stat2 [title]="'Hoàn thành'" [value]="(bookingStatus.completed)" [color]="'#5ac918'" [icon]="'mdi mdi-check'"></app-stat2>
                  </div>
              </div>
          </div> -->
      <!-- end row -->

      <div class="mt-3">
        <div class="row">
          <h6 class="col-9">GIAO DỊCH GẦN ĐÂY</h6>
        </div>
      </div>
      <div class="card">
        <div class="table-responsive">
          <table
            id="basic-datatable"
            class="table table-bordered dt-responsive nowrap no-footer dtr-inline"
          >
            <thead>
              <tr class="bg-table">
                <!-- <th>#</th> -->
                <th>#</th>
                <!-- <th>{{'PAGE.REPORT.Type' | translate}}</th> -->
                <th *ngIf="role ==='admin'">{{'Cửa hàng' | translate}}</th>
                <th>{{'Mã giao dịch' | translate}}</th>
                <th>{{'Thiết bị' | translate}}</th>
                <th>{{'Số tài khoản' | translate}}</th>
                <th>{{'Số tiền' | translate}}</th>
                <!-- <th>{{'PAGE.REPORT.Location' | translate}}</th> -->
                <!-- <th>Detail</th> -->
                <!-- <th>{{'Trạng thái' | translate}}</th> -->
                <th>{{'Ngày giao dịch' | translate}}</th>
                <!-- <th>Ngày cập nhật</th> -->
                <!-- <th>{{'TABLE.UpdatedDate' | translate}}</th> -->
                <!-- <th>{{'TABLE.Actions' | translate}}</th> -->
              </tr>
            </thead>
            <tbody *ngFor="let data of listData;let i=index">
              <!-- <tr (click)="onDetailBooking(data, onDetailBooking)" class="clickable"> -->
              <td>{{i+(pageSize*pageIndex - pageSize + 1)}}</td>
              <!-- <td style="text-decoration: underline; color: #204cd9 ;"
                                  (click)="onDetailBooking(data, detailBooking)">#{{data?.id}}</td> -->
              <!-- <td>
                                  {{data?.id}}
                              </td> -->
              <td *ngIf="role ==='admin'">
                {{data?.workspace_name}}
              </td>
              <td>
                {{data?.code}}
              </td>
              <td>
                {{data?.serial_number}}
              </td>
              <td>
                {{data?.account_number}}
              </td>

              <td>
                {{data?.total | number:'1.0-0'}} <sup><ins>đ</ins></sup>
              </td>
              <!-- <td>
                                  <ng-container *ngIf="data.location_id; else location_other">
                                      {{data?.location_detail?.name}}
                                  </ng-container>
                                  <ng-template #location_other>
                                      {{data?.location_other?.formatted_address}}
                                  </ng-template>
                              </td> -->
              <!-- <td>{{data?.details.}}</td> -->
              <!-- <td>
                                  <span class="badge badge-success" *ngIf="data.status == 'waiting'">Đơn hàng mới</span>
                                  <span class="badge badge-danger" *ngIf="data.status == 'accepted'">Đã được thợ nhận</span>
                                  <span class="badge badge-danger" *ngIf="data.status == 'reject'">Đơn hàng bị từ chối</span>
                                  <span class="badge badge-danger" *ngIf="data.status == 'on_doing'">Đang tiến hành</span>
                                  <span class="badge badge-danger" *ngIf="data.status == 'completed'">Đơn hàng hoàn
                                      thành</span>
                                  <span class="badge badge-danger" *ngIf="data.status == 'canceled'">Đơn hàng bị huỷ</span>
                              </td> -->

              <!-- <td>
                                  <span class="font-size-12 badge badge-danger" *ngIf="data.status == '0'">{{'Đã huy' |
                                      translate}}</span>
                                  <span class="font-size-12 badge badge-warning" *ngIf="data.status == '2'">{{'Đang chờ' |
                                      translate}}</span>
                                  <span class="font-size-12 badge badge-success" *ngIf="data.status == '1'">{{'Hoàn thành' |
                                      translate}}</span>
                              </td> -->
              <td>{{data.created_at | date:'dd/MM/yyyy HH:mm'}}</td>
              <!-- <td>{{data.updated_at | date:'dd/MM/yyyy HH:mm'}}</td> -->

              <!-- <td> -->
              <!-- <a class="dropdown-item" (click)="onDetailBooking(data, detailBooking)">
                                      <i class="mdi mdi-account-edit mr-1"></i>{{'PAGE.REPORT.ViewDetail' | translate}}
                                  </a>
                                  <a class="dropdown-item" *ngIf="data.images?.length > 0"
                                      (click)="onEdit(data, content)">
                                      <i class="mdi mdi-folder-multiple-image mr-1"></i>{{'PAGE.REPORT.ViewImage' |
                                      translate}}
                                  </a> -->

              <!-- <a class="dropdown-item" href="javascript: void(0);" (click)="onDone(data)">
                                      <i class="mdi mdi-check"></i> Hoàn thành đơn
                                  </a>
                                  <a class="dropdown-item" href="javascript: void(0);" (click)="onDelete(data)">
                                      <i class="mdi mdi-delete"></i>Huỷ đơn
                                  </a> -->

              <!-- <div ngbDropdown placement="bottom-left">
                                    <i class="mdi mdi-dots-vertical dropdown-toggle" ngbDropdownToggle
                                        data-toggle="dropdown" aria-expanded="true"></i>
                                    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
              
                                    </div>
                                </div> -->
              <!-- </td> -->
              <!-- </tr> -->
            </tbody>
          </table>
        </div>
        <!-- <div class="card-body">
                  <div class="float-right d-none d-md-inline-block">
                      <div class="btn-group mb-2">
                          <button type="button" class="btn btn-sm btn-light" [ngClass]="{'active': type == 0 }" (click)="type = 0;fetchChart()">{{'PAGE.DASHBOARDS.Today' | translate}}</button>
                          <button type="button" class="btn btn-sm btn-light" [ngClass]="{'active': type == 1 }" (click)="type = 1;fetchChart()">{{'PAGE.DASHBOARDS.7D' | translate}}</button>
                          <button type="button" class="btn btn-sm btn-light" [ngClass]="{'active': type == 2 }" (click)="type = 2;fetchChart()">{{'PAGE.DASHBOARDS.1M' | translate}}</button>
                          <button type="button" class="btn btn-sm btn-light" [ngClass]="{'active': type == 2 }" (click)="type = 2;fetchChart()">Yearly</button>
                          <button type="button" class="btn btn-sm btn-light" [ngClass]="{'active': type == 2 }" (click)="type = 4;fetchChart()">Yearly</button>
                      </div>
                  </div>
                  <h4 class="card-title mb-4">{{'PAGE.DASHBOARDS.QuantityStatistics' | translate}}</h4>
                  <div>

                  </div>
              </div> -->

        <!-- <div class="card-body border-top text-center"> -->

        <!-- <div class="chartjs-chart"> -->
        <!-- Bar Chart -->
        <!-- <ng-container *ngIf="loadedChart">

                        <canvas baseChart [datasets]="chart.datasets" chartType="bar" [legend]="false"
                            [options]="chart.options" height="300" [labels]="chart.labels">
                        </canvas>
                      </ng-container> -->

        <!-- <ng-container *ngIf="loadedChart">

                        <canvas baseChart [datasets]="chart.datasets" chartType="bar"
                            [options]="chart.options" height="300" [labels]="chart.labels">
                        </canvas>
                      </ng-container> -->
        <!-- </div> -->

        <!-- <div class="row">
                      <div class="col-sm-4">
                          <div class="d-inline-flex">
                              <h5 class="mr-2">$12,253</h5>
                              <div class="text-success">
                                  <i class="mdi mdi-menu-up font-size-14"> </i>2.2 %
                              </div>
                          </div>
                          <p class="text-muted text-truncate mb-0">This month</p>
                      </div>

                      <div class="col-sm-4">
                          <div class="mt-4 mt-sm-0">
                              <p class="mb-2 text-muted text-truncate"><i
                                      class="mdi mdi-circle text-primary font-size-10 mr-1"></i>
                                  This Year :</p>
                              <div class="d-inline-flex">
                                  <h5 class="mb-0 mr-2">$ 34,254</h5>
                                  <div class="text-success">
                                      <i class="mdi mdi-menu-up font-size-14"> </i>2.1 %
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-sm-4">
                          <div class="mt-4 mt-sm-0">
                              <p class="mb-2 text-muted text-truncate"><i
                                      class="mdi mdi-circle text-success font-size-10 mr-1"></i>
                                  Previous Year :</p>
                              <div class="d-inline-flex">
                                  <h5 class="mb-0">$ 32,695</h5>
                              </div>
                          </div>
                      </div>
                  </div> -->
        <!-- </div> -->
      </div>
      <!-- <button class="btn btn-info mb-2 col-md-3" (click)="toTrans()">
              {{' Đi tới trang giao dịch' | translate}} <span class="mdi mdi-arrow-right-bold-circle-outline" style="font-size:medium;"></span>
          </button> -->
    </div>

    <!-- <div class="col-xl-4">
          <div class="card">
              <div class="card-body">
                  <div class="float-right">
                      <select class="custom-select custom-select-sm">
                          <option selected>Apr</option>
                          <option value="1">Mar</option>
                          <option value="2">Feb</option>
                          <option value="3">Jan</option>
                      </select>
                  </div>
                  <h4 class="card-title mb-4">Sales Analytics</h4>

                  <apx-chart dir="ltr" class="apex-charts" [series]="salesAnalytics.series"
                      [chart]="salesAnalytics.chart" [legend]="salesAnalytics.legend" [colors]="salesAnalytics.colors"
                      [labels]="revenueChart.labels" [dataLabels]="salesAnalytics.dataLabels"
                      [plotOptions]="salesAnalytics.plotOptions">
                  </apx-chart>
                  <div class="row">
                      <div class="col-4">
                          <div class="text-center mt-4">
                              <p class="mb-2 text-truncate"><i
                                      class="mdi mdi-circle text-primary font-size-10 mr-1"></i> Product A
                              </p>
                              <h5>42 %</h5>
                          </div>
                      </div>
                      <div class="col-4">
                          <div class="text-center mt-4">
                              <p class="mb-2 text-truncate"><i
                                      class="mdi mdi-circle text-success font-size-10 mr-1"></i> Product B
                              </p>
                              <h5>26 %</h5>
                          </div>
                      </div>
                      <div class="col-4">
                          <div class="text-center mt-4">
                              <p class="mb-2 text-truncate"><i
                                      class="mdi mdi-circle text-warning font-size-10 mr-1"></i> Product C
                              </p>
                              <h5>42 %</h5>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div class="card">
              <div class="card-body">
                  <div class="dropdown float-right" ngbDropdown>
                      <a href="javascript: void(0);" class="dropdown-toggle arrow-none card-drop"
                          data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                          <i class="mdi mdi-dots-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                          <a href="javascript:void(0);" class="dropdown-item">Sales Report</a>
                          <a href="javascript:void(0);" class="dropdown-item">Export Report</a>
                          <a href="javascript:void(0);" class="dropdown-item">Profit</a>
                          <a href="javascript:void(0);" class="dropdown-item">Action</a>
                      </div>
                  </div>

                  <h4 class="card-title mb-4">Earning Reports</h4>
                  <div class="text-center">
                      <div class="row">
                          <div class="col-sm-6">
                              <div>
                                  <div class="mb-3">
                                      <apx-chart dir="ltr" class="apex-charts" [series]="sparklineEarning.series"
                                          [chart]="sparklineEarning.chart" [colors]="sparklineEarning.colors"
                                          [labels]="sparklineEarning.labels"
                                          [dataLabels]="sparklineEarning.dataLabels"
                                          [plotOptions]="sparklineEarning.plotOptions">
                                      </apx-chart>
                                  </div>

                                  <p class="text-muted text-truncate mb-2">Weekly Earnings</p>
                                  <h5 class="mb-0">$2,523</h5>
                              </div>
                          </div>

                          <div class="col-sm-6">
                              <div class="mt-5 mt-sm-0">
                                  <div class="mb-3">
                                      <apx-chart dir="ltr" class="apex-charts" [series]="sparklineMonthly.series"
                                          [chart]="sparklineMonthly.chart" [colors]="sparklineMonthly.colors"
                                          [labels]="sparklineMonthly.labels"
                                          [dataLabels]="sparklineMonthly.dataLabels"
                                          [plotOptions]="sparklineMonthly.plotOptions">
                                      </apx-chart>
                                  </div>

                                  <p class="text-muted text-truncate mb-2">Monthly Earnings</p>
                                  <h5 class="mb-0">$11,235</h5>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div> -->
  </div>
  <!-- end row -->

  <!-- <div class="row">
      <div class="col-lg-4">
          <div class="card">
              <div class="card-body">
                  <div class="dropdown float-right" ngbDropdown>
                      <a href="javascript: void(0);" class="dropdown-toggle arrow-none card-drop"
                          data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                          <i class="mdi mdi-dots-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                          <a href="javascript:void(0);" class="dropdown-item">Sales Report</a>
                          <a href="javascript:void(0);" class="dropdown-item">Export Report</a>
                          <a href="javascript:void(0);" class="dropdown-item">Profit</a>
                          <a href="javascript:void(0);" class="dropdown-item">Action</a>
                      </div>
                  </div>

                  <h4 class="card-title mb-3">Sources</h4>

                  <div>
                      <div class="text-center">
                          <p class="mb-2">Total sources</p>
                          <h4>$ 7652</h4>
                          <div class="text-success">
                              <i class="mdi mdi-menu-up font-size-14"> </i>2.2 %
                          </div>
                      </div>

                      <div class="table-responsive mt-4">
                          <table class="table table-hover mb-0 table-centered table-nowrap">
                              <tbody>
                                  <tr>
                                      <td style="width: 60px;">
                                          <div class="avatar-xs">
                                              <div class="avatar-title rounded-circle bg-light">
                                                  <img src="assets/images/companies/img-1.png" alt="" height="20">
                                              </div>
                                          </div>
                                      </td>

                                      <td>
                                          <h5 class="font-size-14 mb-0">Source 1</h5>
                                      </td>
                                      <td>
                                          <div id="spak-chart1"></div>
                                      </td>
                                      <td>
                                          <p class="text-muted mb-0">$ 2478</p>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="avatar-xs">
                                              <div class="avatar-title rounded-circle bg-light">
                                                  <img src="assets/images/companies/img-2.png" alt="" height="20">
                                              </div>
                                          </div>
                                      </td>
                                      <td>
                                          <h5 class="font-size-14 mb-0">Source 2</h5>
                                      </td>

                                      <td>
                                          <div id="spak-chart2"></div>
                                      </td>
                                      <td>
                                          <p class="text-muted mb-0">$ 2625</p>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="avatar-xs">
                                              <div class="avatar-title rounded-circle bg-light">
                                                  <img src="assets/images/companies/img-3.png" alt="" height="20">
                                              </div>
                                          </div>
                                      </td>
                                      <td>
                                          <h5 class="font-size-14 mb-0">Source 3</h5>
                                      </td>
                                      <td>
                                          <div id="spak-chart3"></div>
                                      </td>
                                      <td>
                                          <p class="text-muted mb-0">$ 2856</p>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>

                      <div class="text-center mt-4">
                          <a href="javascript: void(0);" class="btn btn-primary btn-sm">View more</a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-lg-4">
          <div class="card">
              <div class="card-body">
                  <div class="dropdown float-right" ngbDropdown>
                      <a href="javascript: void(0);" class="dropdown-toggle arrow-none card-drop"
                          data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                          <i class="mdi mdi-dots-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>

                          <a href="javascript:void(0);" class="dropdown-item">Sales Report</a>

                          <a href="javascript:void(0);" class="dropdown-item">Export Report</a>

                          <a href="javascript:void(0);" class="dropdown-item">Profit</a>

                          <a href="javascript:void(0);" class="dropdown-item">Action</a>
                      </div>
                  </div>

                  <h4 class="card-title mb-4">Recent Activity Feed</h4>

                  <perfect-scrollbar style="height: 330px;">
                      <ul class="list-unstyled activity-wid">
                          <li class="activity-list">
                              <div class="activity-icon avatar-xs">
                                  <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                                      <i class="ri-edit-2-fill"></i>
                                  </span>
                              </div>
                              <div>
                                  <div>
                                      <h5 class="font-size-13">28 Apr, 2020 <small class="text-muted">12:07 am</small>
                                      </h5>
                                  </div>

                                  <div>
                                      <p class="text-muted mb-0">Responded to need “Volunteer Activities”</p>
                                  </div>
                              </div>
                          </li>
                          <li class="activity-list">
                              <div class="activity-icon avatar-xs">
                                  <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                                      <i class="ri-user-2-fill"></i>
                                  </span>
                              </div>
                              <div>
                                  <div>
                                      <h5 class="font-size-13">21 Apr, 2020 <small class="text-muted">08:01 pm</small>
                                      </h5>
                                  </div>

                                  <div>
                                      <p class="text-muted mb-0">Added an interest “Volunteer Activities”</p>
                                  </div>
                              </div>
                          </li>
                          <li class="activity-list">
                              <div class="activity-icon avatar-xs">
                                  <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                                      <i class="ri-bar-chart-fill"></i>
                                  </span>
                              </div>
                              <div>
                                  <div>
                                      <h5 class="font-size-13">17 Apr, 2020 <small class="text-muted">09:23 am</small>
                                      </h5>
                                  </div>

                                  <div>
                                      <p class="text-muted mb-0">Joined the group “Boardsmanship Forum”</p>
                                  </div>
                              </div>
                          </li>
                          <li class="activity-list">
                              <div class="activity-icon avatar-xs">
                                  <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                                      <i class="ri-mail-fill"></i>
                                  </span>
                              </div>
                              <div>
                                  <div>
                                      <h5 class="font-size-13">11 Apr, 2020 <small class="text-muted">05:10 pm</small>
                                      </h5>
                                  </div>

                                  <div>
                                      <p class="text-muted mb-0">Responded to need “In-Kind Opportunity”</p>
                                  </div>
                              </div>
                          </li>
                          <li class="activity-list">
                              <div class="activity-icon avatar-xs">
                                  <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                                      <i class="ri-calendar-2-fill"></i>
                                  </span>
                              </div>
                              <div>
                                  <div>
                                      <h5 class="font-size-13">07 Apr, 2020 <small class="text-muted">12:47 pm</small>
                                      </h5>
                                  </div>

                                  <div>
                                      <p class="text-muted mb-0">Created need “Volunteer Activities”</p>
                                  </div>
                              </div>
                          </li>
                          <li class="activity-list">
                              <div class="activity-icon avatar-xs">
                                  <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                                      <i class="ri-edit-2-fill"></i>
                                  </span>
                              </div>
                              <div>
                                  <div>
                                      <h5 class="font-size-13">05 Apr, 2020 <small class="text-muted">03:09 pm</small>
                                      </h5>
                                  </div>

                                  <div>
                                      <p class="text-muted mb-0">Attending the event “Some New Event”</p>
                                  </div>
                              </div>
                          </li>
                          <li class="activity-list">
                              <div class="activity-icon avatar-xs">
                                  <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                                      <i class="ri-user-2-fill"></i>
                                  </span>
                              </div>
                              <div>
                                  <div>
                                      <h5 class="font-size-13">02 Apr, 2020 <small class="text-muted">12:07 am</small>
                                      </h5>
                                  </div>

                                  <div>
                                      <p class="text-muted mb-0">Responded to need “In-Kind Opportunity”</p>
                                  </div>
                              </div>
                          </li>
                      </ul>
                  </perfect-scrollbar>
              </div>
          </div>
      </div>
      <div class="col-lg-4">
          <div class="card">
              <div class="card-body">
                  <div class="dropdown float-right" ngbDropdown>
                      <a href="javascript: void(0);" class="dropdown-toggle arrow-none card-drop"
                          data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                          <i class="mdi mdi-dots-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>

                          <a href="javascript:void(0);" class="dropdown-item">Sales Report</a>

                          <a href="javascript:void(0);" class="dropdown-item">Export Report</a>

                          <a href="javascript:void(0);" class="dropdown-item">Profit</a>

                          <a href="javascript:void(0);" class="dropdown-item">Action</a>
                      </div>
                  </div>

                  <h4 class="card-title mb-4">Revenue by Locations</h4>
                  <div style="height: 196px;" leaflet [leafletOptions]="options">
                  </div>
                  <div class="row justify-content-center">
                      <div class="col-xl-5 col-md-6">
                          <div class="mt-2">
                              <div class="clearfix py-2">
                                  <h5 class="float-right font-size-16 m-0">$ 2542</h5>
                                  <p class="text-muted mb-0 text-truncate">California :</p>

                              </div>
                              <div class="clearfix py-2">
                                  <h5 class="float-right font-size-16 m-0">$ 2245</h5>
                                  <p class="text-muted mb-0 text-truncate">Nevada :</p>
                              </div>
                          </div>
                      </div>
                      <div class="col-xl-5 offset-xl-1 col-md-6">
                          <div class="mt-2">
                              <div class="clearfix py-2">
                                  <h5 class="float-right font-size-16 m-0">$ 2156</h5>
                                  <p class="text-muted mb-0 text-truncate">Montana :</p>

                              </div>
                              <div class="clearfix py-2">
                                  <h5 class="float-right font-size-16 m-0">$ 1845</h5>
                                  <p class="text-muted mb-0 text-truncate">Texas :</p>

                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="text-center mt-4">
                      <a href="javascript: void(0);" class="btn btn-primary btn-sm">Learn more</a>
                  </div>
              </div>
          </div>
      </div>
  </div> -->
  <!-- end row -->

  <!-- <div class="row">
      <div class="col-lg-4">
          <div class="card">
              <div class="card-body border-bottom">

                  <div class="user-chat-border">
                      <div class="row">
                          <div class="col-md-5 col-9">
                              <h5 class="font-size-15 mb-1">Frank Vickery</h5>
                              <p class="text-muted mb-0"><i class="mdi mdi-circle text-success align-middle mr-1"></i>
                                  Active now</p>
                          </div>
                          <div class="col-md-7 col-3">
                              <ul class="list-inline user-chat-nav text-right mb-0">
                                  <li class="list-inline-item">
                                      <div class="dropdown" ngbDropdown>
                                          <button class="btn nav-btn dropdown-toggle" type="button"
                                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                              ngbDropdownToggle>
                                              <i class="mdi mdi-magnify"></i>
                                          </button>
                                          <div class="dropdown-menu dropdown-menu-right dropdown-menu-md p-0"
                                              ngbDropdownMenu>
                                              <form class="p-2">
                                                  <div class="search-box">
                                                      <div class="position-relative">
                                                          <input type="text"
                                                              class="form-control rounded bg-light border-0"
                                                              placeholder="Search...">
                                                          <i class="mdi mdi-magnify search-icon"></i>
                                                      </div>
                                                  </div>
                                              </form>
                                          </div>
                                      </div>
                                  </li>
                                  <li class="list-inline-item d-none d-sm-inline-block">
                                      <div class="dropdown" ngbDropdown>
                                          <button class="btn nav-btn dropdown-toggle" type="button"
                                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                              ngbDropdownToggle>
                                              <i class="mdi mdi-cog"></i>
                                          </button>
                                          <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                                              <a class="dropdown-item" href="#">View Profile</a>
                                              <a class="dropdown-item" href="#">Clear chat</a>
                                              <a class="dropdown-item" href="#">Muted</a>
                                              <a class="dropdown-item" href="#">Delete</a>
                                          </div>
                                      </div>
                                  </li>

                                  <li class="list-inline-item">
                                      <div class="dropdown" ngbDropdown>
                                          <button class="btn nav-btn dropdown-toggle" type="button"
                                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                              ngbDropdownToggle>
                                              <i class="mdi mdi-dots-horizontal"></i>
                                          </button>
                                          <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                                              <a class="dropdown-item" href="#">Action</a>
                                              <a class="dropdown-item" href="#">Another action</a>
                                              <a class="dropdown-item" href="#">Something else</a>
                                          </div>
                                      </div>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="card-body">
                  <div class="chat-widget">
                      <div class="chat-conversation">
                          <perfect-scrollbar style="height: 292px;">
                              <ul class="list-unstyled mb-0 pr-3" *ngFor="let data of chatData"
                                  [ngClass]="{ 'right': data.align === 'right' }">
                                  <li *ngIf="!data.text">
                                      <div class="conversation-list">
                                          <div class="chat-avatar">
                                              <img src="{{data.image}}" alt="">
                                          </div>
                                          <div class="ctext-wrap">
                                              <div class="conversation-name">{{data.name}}</div>
                                              <div class="ctext-wrap-content">
                                                  <p class="mb-0">
                                                      {{data.message}}
                                                  </p>
                                              </div>
                                              <p class="chat-time mb-0"><i
                                                      class="mdi mdi-clock-outline align-middle mr-1"></i>
                                                  {{data.time}}
                                              </p>
                                          </div>
                                      </div>
                                  </li>
                                  <li *ngIf="data.text">
                                      <div class="chat-day-title">
                                          <span class="title">{{data.text}}</span>
                                      </div>
                                  </li>
                              </ul>
                          </perfect-scrollbar>
                      </div>
                  </div>
              </div>
              <div class="p-3 chat-input-section border-top">
                  <form (ngSubmit)="messageSave()" [formGroup]="formData" class="row">
                      <div class="col">
                          <div class="position-relative">
                              <input type="text" class="form-control chat-input" placeholder="Enter Message..."
                                  formControlName="message"
                                  [ngClass]="{'is-invalid': chatSubmit && form.message.errors}">
                              <div *ngIf="chatSubmit && form.message.errors" class="invalid-feedback">
                                  <span *ngIf="form.message.errors.required">This value is required.</span>
                              </div>
                          </div>
                      </div>
                      <div class="col-auto">
                          <button type="submit" class="btn btn-primary chat-send w-md waves-effect waves-light"><span
                                  class="d-none d-sm-inline-block mr-2">Send</span> <i
                                  class="mdi mdi-send"></i></button>
                      </div>
                  </form>
              </div>
          </div>
      </div>
      <div class="col-lg-8">
          <div class="card">
              <div class="card-body">
                  <div class="dropdown float-right" ngbDropdown>
                      <a href="javascript: void(0);" class="dropdown-toggle arrow-none card-drop" ngbDropdownToggle
                          data-toggle="dropdown" aria-expanded="false">
                          <i class="mdi mdi-dots-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>

                          <a href="javascript:void(0);" class="dropdown-item">Sales Report</a>

                          <a href="javascript:void(0);" class="dropdown-item">Export Report</a>

                          <a href="javascript:void(0);" class="dropdown-item">Profit</a>

                          <a href="javascript:void(0);" class="dropdown-item">Action</a>
                      </div>
                  </div>
                  <h4 class="card-title mb-4">Latest Transactions</h4>
                  <div class="row">
                      <div class="col-sm-12 col-md-12">
                          <div id="tickets-table_filter" class="dataTables_filter text-md-right"><label
                                  class="d-inline-flex align-items-center">Search:
                                  <input type="text" name="searchTerm" class="form-control form-control-sm ml-2"
                                      aria-controls="tickets-table" [(ngModel)]="term" /></label></div>
                      </div>
                  </div>
                  <div class="table-responsive">
                      <table class="table table-centered">
                          <thead class="thead-light">
                              <tr>
                                  <th>Order ID</th>
                                  <th>Date</th>
                                  <th>Billing Name</th>
                                  <th>Total</th>
                                  <th>Payment Status</th>
                                  <th>Action</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr *ngFor="let item of transactions|filter:term">
                                  <td>{{item.orderid}}</td>
                                  <td>{{item.date}}</td>
                                  <td>{{item.billingname}}</td>
                                  <td>{{item.total}}</td>
                                  <td>
                                      <div class="badge font-size-12" [ngClass]="{'badge-soft-danger': item.paymentstatus === 'Chargeback',
                    'badge-soft-success':item.paymentstatus === 'Paid',
                    'badge-soft-warning': item.paymentstatus === 'Unpaid'}">{{item.paymentstatus}}
                                      </div>
                                  </td>
                                  <td>
                                      <a href="javascript:void(0);" class="mr-3 text-primary" ngbTooltip="Edit"><i
                                              class="mdi mdi-pencil font-size-18"></i></a>
                                      <a href="javascript:void(0);" class="text-danger" ngbTooltip="Delete"><i
                                              class="mdi mdi-trash-can font-size-18"></i></a>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
      </div>
  </div> -->
  <!-- end row -->
</div>
