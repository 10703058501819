<ng-template #loading>
  <ng-template #loading>
    <div class="loading-overlay">
      <div class="spinner"></div>
    </div>
  </ng-template>
</ng-template>
<div *ngIf="chartOptions && chartOptionsQR && chartOptionsService">
  <div *ngIf="role === 'operation'; else nonOperation" class="dashboard-new">
    <div class="row d-flex">
      <div class="col-md-6 mb-2">
        <div class="card-custom p-1">
          <div class="row">
            <div class="card-body-big px-3 py-2 col-4 custom-border-right">
              <span>Tổng đơn hàng</span>
              <div class="row">
                <div class="col-4 counter-card-icon text-right">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#D32730"
                    class="size-6"
                  >
                    <path
                      d="M2.25 2.25a.75.75 0 0 0 0 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 0 0-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 0 0 0-1.5H5.378A2.25 2.25 0 0 1 7.5 15h11.218a.75.75 0 0 0 .674-.421 60.358 60.358 0 0 0 2.96-7.228.75.75 0 0 0-.525-.965A60.864 60.864 0 0 0 5.68 4.509l-.232-.867A1.875 1.875 0 0 0 3.636 2.25H2.25ZM3.75 20.25a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM16.5 20.25a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
                    />
                  </svg>
                </div>
                <div
                  class="col-8 d-flex align-items-center justify-content-center"
                >
                  <div class="counter-card-text">
                    <h3>{{data.totalCustomerOrder}}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body-big px-3 py-2 col-4 custom-border-right">
              <span>Đơn trực tiếp</span>
              <div class="row">
                <div class="col-4 counter-card-icon text-right">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#00A465"
                    class="size-6"
                  >
                    <path
                      d="M5.223 2.25c-.497 0-.974.198-1.325.55l-1.3 1.298A3.75 3.75 0 0 0 7.5 9.75c.627.47 1.406.75 2.25.75.844 0 1.624-.28 2.25-.75.626.47 1.406.75 2.25.75.844 0 1.623-.28 2.25-.75a3.75 3.75 0 0 0 4.902-5.652l-1.3-1.299a1.875 1.875 0 0 0-1.325-.549H5.223Z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M3 20.25v-8.755c1.42.674 3.08.673 4.5 0A5.234 5.234 0 0 0 9.75 12c.804 0 1.568-.182 2.25-.506a5.234 5.234 0 0 0 2.25.506c.804 0 1.567-.182 2.25-.506 1.42.674 3.08.675 4.5.001v8.755h.75a.75.75 0 0 1 0 1.5H2.25a.75.75 0 0 1 0-1.5H3Zm3-6a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75v-3Zm8.25-.75a.75.75 0 0 0-.75.75v5.25c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75v-5.25a.75.75 0 0 0-.75-.75h-3Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div
                  class="col-8 d-flex align-items-center justify-content-center"
                >
                  <div class="counter-card-text">
                    <h3>{{data.totalOrderDirect}}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body-big px-3 py-2 col-4">
              <span>Đơn online</span>
              <div class="row">
                <div class="col-4 counter-card-icon text-right">
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#DD91EE" class="size-6">
                            <path fill-rule="evenodd"
                                d="M1.371 8.143c5.858-5.857 15.356-5.857 21.213 0a.75.75 0 0 1 0 1.061l-.53.53a.75.75 0 0 1-1.06 0c-4.98-4.979-13.053-4.979-18.032 0a.75.75 0 0 1-1.06 0l-.53-.53a.75.75 0 0 1 0-1.06Zm3.182 3.182c4.1-4.1 10.749-4.1 14.85 0a.75.75 0 0 1 0 1.061l-.53.53a.75.75 0 0 1-1.062 0 8.25 8.25 0 0 0-11.667 0 .75.75 0 0 1-1.06 0l-.53-.53a.75.75 0 0 1 0-1.06Zm3.204 3.182a6 6 0 0 1 8.486 0 .75.75 0 0 1 0 1.061l-.53.53a.75.75 0 0 1-1.061 0 3.75 3.75 0 0 0-5.304 0 .75.75 0 0 1-1.06 0l-.53-.53a.75.75 0 0 1 0-1.06Zm3.182 3.182a1.5 1.5 0 0 1 2.122 0 .75.75 0 0 1 0 1.061l-.53.53a.75.75 0 0 1-1.061 0l-.53-.53a.75.75 0 0 1 0-1.06Z"
                                clip-rule="evenodd" />
                        </svg> -->
                  <img
                    class=""
                    width="50"
                    src=" https://cdn-icons-png.flaticon.com/512/9674/9674659.png"
                    alt=""
                  />
                </div>
                <div
                  class="col-8 d-flex align-items-center justify-content-center"
                >
                  <div class="counter-card-text">
                    <h3>{{data.totalOrderShipper}}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-2">
        <div class="card-custom p-1">
          <div class="row">
            <div class="card-body-big px-3 py-2 col-4 custom-border-right">
              <span>Gói 12 tháng</span>
              <div class="row">
                <div class="col-4 counter-card-icon text-right">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#C46210"
                    class="size-6"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div
                  class="col-8 d-flex align-items-center justify-content-center"
                >
                  <div class="counter-card-text">
                    <h3>{{data.totalOrder12M}}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body-big px-3 py-2 col-4 custom-border-right">
              <span>Gói 6 tháng</span>
              <div class="row">
                <div class="col-4 counter-card-icon text-right">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#595E4C"
                    class="size-6"
                  >
                    <path
                      d="M12 11.993a.75.75 0 0 0-.75.75v.006c0 .414.336.75.75.75h.006a.75.75 0 0 0 .75-.75v-.006a.75.75 0 0 0-.75-.75H12ZM12 16.494a.75.75 0 0 0-.75.75v.005c0 .414.335.75.75.75h.005a.75.75 0 0 0 .75-.75v-.005a.75.75 0 0 0-.75-.75H12ZM8.999 17.244a.75.75 0 0 1 .75-.75h.006a.75.75 0 0 1 .75.75v.006a.75.75 0 0 1-.75.75h-.006a.75.75 0 0 1-.75-.75v-.006ZM7.499 16.494a.75.75 0 0 0-.75.75v.005c0 .414.336.75.75.75h.005a.75.75 0 0 0 .75-.75v-.005a.75.75 0 0 0-.75-.75H7.5ZM13.499 14.997a.75.75 0 0 1 .75-.75h.006a.75.75 0 0 1 .75.75v.005a.75.75 0 0 1-.75.75h-.006a.75.75 0 0 1-.75-.75v-.005ZM14.25 16.494a.75.75 0 0 0-.75.75v.006c0 .414.335.75.75.75h.005a.75.75 0 0 0 .75-.75v-.006a.75.75 0 0 0-.75-.75h-.005ZM15.75 14.995a.75.75 0 0 1 .75-.75h.005a.75.75 0 0 1 .75.75v.006a.75.75 0 0 1-.75.75H16.5a.75.75 0 0 1-.75-.75v-.006ZM13.498 12.743a.75.75 0 0 1 .75-.75h2.25a.75.75 0 1 1 0 1.5h-2.25a.75.75 0 0 1-.75-.75ZM6.748 14.993a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M18 2.993a.75.75 0 0 0-1.5 0v1.5h-9V2.994a.75.75 0 1 0-1.5 0v1.497h-.752a3 3 0 0 0-3 3v11.252a3 3 0 0 0 3 3h13.5a3 3 0 0 0 3-3V7.492a3 3 0 0 0-3-3H18V2.993ZM3.748 18.743v-7.5a1.5 1.5 0 0 1 1.5-1.5h13.5a1.5 1.5 0 0 1 1.5 1.5v7.5a1.5 1.5 0 0 1-1.5 1.5h-13.5a1.5 1.5 0 0 1-1.5-1.5Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div
                  class="col-8 d-flex align-items-center justify-content-center"
                >
                  <div class="counter-card-text">
                    <h3>{{data.totalOrder6M}}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body-big px-3 py-2 col-4">
              <span>Gói 3 tháng</span>
              <div class="row">
                <div class="col-4 counter-card-icon text-right">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#6A397B"
                    class="size-6"
                  >
                    <path
                      d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div
                  class="col-8 d-flex align-items-center justify-content-center"
                >
                  <div class="counter-card-text">
                    <h3>{{data.totalOrder3M}}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-2" *ngIf="role !== 'operation'">
        <div class="card-custom p-1">
          <div class="chart">
            <div id="chart" class="pt-2">
              <apx-chart
                [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [dataLabels]="chartOptions.dataLabels"
                [plotOptions]="chartOptions.plotOptions"
                [yaxis]="chartOptions.yaxis"
                [xaxis]="chartOptions.xaxis"
                [fill]="chartOptions.fill"
                [title]="chartOptions.title"
              ></apx-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-2">
        <div class="card-custom p-1">
          <div class="row">
            <div class="card-body-big px-3 py-2 col-4 custom-border-right">
              <span>Đơn chờ GHN</span>
              <div class="row">
                <div class="col-4 counter-card-icon text-right">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#486241"
                    class="size-6"
                  >
                    <path
                      d="M3.375 4.5C2.339 4.5 1.5 5.34 1.5 6.375V13.5h12V6.375c0-1.036-.84-1.875-1.875-1.875h-8.25ZM13.5 15h-12v2.625c0 1.035.84 1.875 1.875 1.875h.375a3 3 0 1 1 6 0h3a.75.75 0 0 0 .75-.75V15Z"
                    />
                    <path
                      d="M8.25 19.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0ZM15.75 6.75a.75.75 0 0 0-.75.75v11.25c0 .087.015.17.042.248a3 3 0 0 1 5.958.464c.853-.175 1.522-.935 1.464-1.883a18.659 18.659 0 0 0-3.732-10.104 1.837 1.837 0 0 0-1.47-.725H15.75Z"
                    />
                    <path d="M19.5 19.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
                  </svg>
                </div>
                <div
                  class="col-8 d-flex align-items-center justify-content-center"
                >
                  <div class="counter-card-text">
                    <h3>{{data.totalOrderGHN}}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body-big px-3 py-2 col-4 custom-border-right">
              <span>Đơn đang giao</span>
              <div class="row">
                <div class="col-4 counter-card-icon text-right">
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#F29388"
                                        class="size-6">
                                        <path fill-rule="evenodd"
                                            d="M5.625 1.5H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875ZM9.75 14.25a.75.75 0 0 0 0 1.5H15a.75.75 0 0 0 0-1.5H9.75Z"
                                            clip-rule="evenodd" />
                                        <path
                                            d="M14.25 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 16.5 7.5h-1.875a.375.375 0 0 1-.375-.375V5.25Z" />
                                    </svg> -->
                  <img
                    class=""
                    width="50"
                    src="https://cdn2.iconfinder.com/data/icons/e-commerce-color-6/512/delivery_waiting-512.png"
                    alt=""
                  />
                </div>
                <div
                  class="col-8 d-flex align-items-center justify-content-center"
                >
                  <div class="counter-card-text">
                    <h3>{{data.totalOrderInTransit}}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body-big px-3 py-2 col-4">
              <span>Đơn đã giao</span>
              <div class="row">
                <div class="col-4 counter-card-icon text-right">
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#7CB9E8"
                                        class="size-6">
                                        <path fill-rule="evenodd"
                                            d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625ZM7.5 15a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 7.5 15Zm.75 2.25a.75.75 0 0 0 0 1.5H12a.75.75 0 0 0 0-1.5H8.25Z"
                                            clip-rule="evenodd" />
                                        <path
                                            d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                                    </svg> -->
                  <img
                    class=""
                    width="50"
                    src="https://cdn-icons-png.flaticon.com/512/12964/12964027.png"
                    alt=""
                  />
                </div>
                <div
                  class="col-8 d-flex align-items-center justify-content-center"
                >
                  <div class="counter-card-text">
                    <h3>{{data.totalOrderDelivered}}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-2" *ngIf="role !== 'operation'">
        <div class="card-custom p-1">
          <div class="row">
            <div class="card-body-big px-3 py-2 col-4 custom-border-right">
              <span>Tổng số loa</span>
              <div class="row">
                <div class="col-4 counter-card-icon text-right">
                  <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#71BC68"
                    class="size-6"
                  >
                    <path
                      d="M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5zm2 0v10h14V5H5zm-3 12h20v2H2v-2z"
                    />
                  </svg> -->
                  <img
                    src="assets/images/logo_qrpaybox-bup.png"
                    class="rounded-circle"
                    alt=""
                    width="50"
                  />
                </div>
                <div
                  class="col-8 d-flex align-items-center justify-content-center"
                >
                  <div class="counter-card-text">
                    <h3>{{data.totalDeviceStock}}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body-big px-3 py-2 col-4 custom-border-right">
              <span>Loa đã bán</span>
              <div class="row">
                <div class="col-4 counter-card-icon text-right">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#B22B27"
                    class="size-6"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3.75 3.375c0-1.036.84-1.875 1.875-1.875H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375Zm10.5 1.875a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 16.5 7.5h-1.875a.375.375 0 0 1-.375-.375V5.25ZM12 10.5a.75.75 0 0 1 .75.75v.028a9.727 9.727 0 0 1 1.687.28.75.75 0 1 1-.374 1.452 8.207 8.207 0 0 0-1.313-.226v1.68l.969.332c.67.23 1.281.85 1.281 1.704 0 .158-.007.314-.02.468-.083.931-.83 1.582-1.669 1.695a9.776 9.776 0 0 1-.561.059v.028a.75.75 0 0 1-1.5 0v-.029a9.724 9.724 0 0 1-1.687-.278.75.75 0 0 1 .374-1.453c.425.11.864.186 1.313.226v-1.68l-.968-.332C9.612 14.974 9 14.354 9 13.5c0-.158.007-.314.02-.468.083-.931.831-1.582 1.67-1.694.185-.025.372-.045.56-.06v-.028a.75.75 0 0 1 .75-.75Zm-1.11 2.324c.119-.016.239-.03.36-.04v1.166l-.482-.165c-.208-.072-.268-.211-.268-.285 0-.113.005-.225.015-.336.013-.146.14-.309.374-.34Zm1.86 4.392V16.05l.482.165c.208.072.268.211.268.285 0 .113-.005.225-.015.336-.012.146-.14.309-.374.34-.12.016-.24.03-.361.04Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div
                  class="col-8 d-flex align-items-center justify-content-center"
                >
                  <div class="counter-card-text">
                    <h3>{{data.totalDeviceSold}}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body-big px-3 py-2 col-4">
              <span>Loa tồn kho</span>
              <div class="row">
                <div class="col-4 counter-card-icon text-right">
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#C871D2"
                                        class="size-6">
                                        <path fill-rule="evenodd"
                                            d="M5.625 1.5H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875ZM9.75 17.25a.75.75 0 0 0-1.5 0V18a.75.75 0 0 0 1.5 0v-.75Zm2.25-3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0v-3a.75.75 0 0 1 .75-.75Zm3.75-1.5a.75.75 0 0 0-1.5 0V18a.75.75 0 0 0 1.5 0v-5.25Z"
                                            clip-rule="evenodd" />
                                        <path
                                            d="M14.25 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 16.5 7.5h-1.875a.375.375 0 0 1-.375-.375V5.25Z" />
                                    </svg> -->
                  <img
                    class=""
                    width="50"
                    src="https://cdn-icons-png.flaticon.com/256/407/407826.png"
                    alt=""
                  />
                </div>
                <div
                  class="col-8 d-flex align-items-center justify-content-center"
                >
                  <div class="counter-card-text">
                    <h3>{{data.inventoryDevice}}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-2">
        <div class="card-custom p-1">
          <div class="row">
            <div class="card-body-big px-3 py-2 col-4 custom-border-right">
              <span>Tổng số cửa hàng</span>
              <div class="row">
                <div class="col-4 counter-card-icon text-right">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="size-6"
                  >
                    <path
                      d="M5.223 2.25c-.497 0-.974.198-1.325.55l-1.3 1.298A3.75 3.75 0 0 0 7.5 9.75c.627.47 1.406.75 2.25.75.844 0 1.624-.28 2.25-.75.626.47 1.406.75 2.25.75.844 0 1.623-.28 2.25-.75a3.75 3.75 0 0 0 4.902-5.652l-1.3-1.299a1.875 1.875 0 0 0-1.325-.549H5.223Z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M3 20.25v-8.755c1.42.674 3.08.673 4.5 0A5.234 5.234 0 0 0 9.75 12c.804 0 1.568-.182 2.25-.506a5.234 5.234 0 0 0 2.25.506c.804 0 1.567-.182 2.25-.506 1.42.674 3.08.675 4.5.001v8.755h.75a.75.75 0 0 1 0 1.5H2.25a.75.75 0 0 1 0-1.5H3Zm3-6a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75v-3Zm8.25-.75a.75.75 0 0 0-.75.75v5.25c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75v-5.25a.75.75 0 0 0-.75-.75h-3Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div
                  class="col-8 d-flex align-items-center justify-content-center"
                >
                  <div class="counter-card-text">
                    <h3>{{data.totalWorkspaces}}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body-big px-3 py-2 col-4 custom-border-right">
              <span>Tổng số giao dịch QR</span>
              <div class="row">
                <div class="col-4 counter-card-icon text-right">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#D7B5A0"
                    class="size-6"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 4.875C3 3.839 3.84 3 4.875 3h4.5c1.036 0 1.875.84 1.875 1.875v4.5c0 1.036-.84 1.875-1.875 1.875h-4.5A1.875 1.875 0 0 1 3 9.375v-4.5ZM4.875 4.5a.375.375 0 0 0-.375.375v4.5c0 .207.168.375.375.375h4.5a.375.375 0 0 0 .375-.375v-4.5a.375.375 0 0 0-.375-.375h-4.5Zm7.875.375c0-1.036.84-1.875 1.875-1.875h4.5C20.16 3 21 3.84 21 4.875v4.5c0 1.036-.84 1.875-1.875 1.875h-4.5a1.875 1.875 0 0 1-1.875-1.875v-4.5Zm1.875-.375a.375.375 0 0 0-.375.375v4.5c0 .207.168.375.375.375h4.5a.375.375 0 0 0 .375-.375v-4.5a.375.375 0 0 0-.375-.375h-4.5ZM6 6.75A.75.75 0 0 1 6.75 6h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75A.75.75 0 0 1 6 7.5v-.75Zm9.75 0A.75.75 0 0 1 16.5 6h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75ZM3 14.625c0-1.036.84-1.875 1.875-1.875h4.5c1.036 0 1.875.84 1.875 1.875v4.5c0 1.035-.84 1.875-1.875 1.875h-4.5A1.875 1.875 0 0 1 3 19.125v-4.5Zm1.875-.375a.375.375 0 0 0-.375.375v4.5c0 .207.168.375.375.375h4.5a.375.375 0 0 0 .375-.375v-4.5a.375.375 0 0 0-.375-.375h-4.5Zm7.875-.75a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Zm6 0a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75ZM6 16.5a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Zm9.75 0a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Zm-3 3a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Zm6 0a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div
                  class="col-8 d-flex align-items-center justify-content-center"
                >
                  <div class="counter-card-text">
                    <h3>{{data.totalTransactions}}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body-big px-3 py-2 col-4">
              <span>Tổng giá trị giao dịch</span>
              <div class="row" style="height: 40px;">
                <!-- <div class="col-2 counter-card-icon text-right">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FF5A5F"
                                        class="size-6">
                                        <path
                                            d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 0 1-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004ZM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 0 1-.921.42Z" />
                                        <path fill-rule="evenodd"
                                            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v.816a3.836 3.836 0 0 0-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 0 1-.921-.421l-.879-.66a.75.75 0 0 0-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 0 0 1.5 0v-.81a4.124 4.124 0 0 0 1.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 0 0-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 0 0 .933-1.175l-.415-.33a3.836 3.836 0 0 0-1.719-.755V6Z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </div> -->
                <div class="col-12 d-flex align-items-center">
                  <div class="counter-card-text">
                    <div class="total-amount font-weight-bold">
                      {{ data.totalAmount | number:'1.0-0' }} VNĐ
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-2">
        <div class="card-custom p-1">
          <div id="chart" class="pt-2">
            <apx-chart
              [series]="chartOptionsQR.series"
              [chart]="chartOptionsQR.chart"
              [yaxis]="chartOptionsQR.yaxis"
              [xaxis]="chartOptionsQR.xaxis"
              [labels]="chartOptionsQR.labels"
              [stroke]="chartOptionsQR.stroke"
              [title]="chartOptionsQR.title"
              [dataLabels]="chartOptionsQR.dataLabels"
              [fill]="chartOptionsQR.fill"
              [tooltip]="chartOptionsQR.tooltip"
            ></apx-chart>
          </div>
        </div>
      </div>
      <div
        [ngClass]="{'col-md-12': role !== 'operation', 'col-md-6': role === 'operation'}"
        class="mb-2"
      >
        <div class="card-custom p-1">
          <div id="chart" class="pt-2">
            <apx-chart
              [series]="chartOptionsService.series"
              [chart]="chartOptionsService.chart"
              [dataLabels]="chartOptionsService.dataLabels"
              [plotOptions]="chartOptionsService.plotOptions"
              [yaxis]="chartOptionsService.yaxis"
              [legend]="chartOptionsService.legend"
              [fill]="chartOptionsService.fill"
              [stroke]="chartOptionsService.stroke"
              [tooltip]="chartOptionsService.tooltip"
              [xaxis]="chartOptionsService.xaxis"
              [title]="chartOptionsService.title"
            ></apx-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #nonOperation>
    <div class="dashboard-new">
      <div class="group group1">
        <div class="row">
          <div class="card-body-big px-3 py-2 col-4 custom-border-right">
            <span>Tổng đơn hàng</span>
            <div class="row">
              <div class="col-4 counter-card-icon text-right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#D32730"
                  class="size-6"
                >
                  <path
                    d="M2.25 2.25a.75.75 0 0 0 0 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 0 0-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 0 0 0-1.5H5.378A2.25 2.25 0 0 1 7.5 15h11.218a.75.75 0 0 0 .674-.421 60.358 60.358 0 0 0 2.96-7.228.75.75 0 0 0-.525-.965A60.864 60.864 0 0 0 5.68 4.509l-.232-.867A1.875 1.875 0 0 0 3.636 2.25H2.25ZM3.75 20.25a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM16.5 20.25a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
                  />
                </svg>
              </div>
              <div
                class="col-8 d-flex align-items-center justify-content-center"
              >
                <div class="counter-card-text">
                  <h3>{{data.totalCustomerOrder}}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body-big px-3 py-2 col-4 custom-border-right">
            <span>Đơn trực tiếp</span>
            <div class="row">
              <div class="col-4 counter-card-icon text-right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#00A465"
                  class="size-6"
                >
                  <path
                    d="M5.223 2.25c-.497 0-.974.198-1.325.55l-1.3 1.298A3.75 3.75 0 0 0 7.5 9.75c.627.47 1.406.75 2.25.75.844 0 1.624-.28 2.25-.75.626.47 1.406.75 2.25.75.844 0 1.623-.28 2.25-.75a3.75 3.75 0 0 0 4.902-5.652l-1.3-1.299a1.875 1.875 0 0 0-1.325-.549H5.223Z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M3 20.25v-8.755c1.42.674 3.08.673 4.5 0A5.234 5.234 0 0 0 9.75 12c.804 0 1.568-.182 2.25-.506a5.234 5.234 0 0 0 2.25.506c.804 0 1.567-.182 2.25-.506 1.42.674 3.08.675 4.5.001v8.755h.75a.75.75 0 0 1 0 1.5H2.25a.75.75 0 0 1 0-1.5H3Zm3-6a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75v-3Zm8.25-.75a.75.75 0 0 0-.75.75v5.25c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75v-5.25a.75.75 0 0 0-.75-.75h-3Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div
                class="col-8 d-flex align-items-center justify-content-center"
              >
                <div class="counter-card-text">
                  <h3>{{data.totalOrderDirect}}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body-big px-3 py-2 col-4">
            <span>Đơn online</span>
            <div class="row">
              <div class="col-4 counter-card-icon text-right">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#DD91EE" class="size-6">
                            <path fill-rule="evenodd"
                                d="M1.371 8.143c5.858-5.857 15.356-5.857 21.213 0a.75.75 0 0 1 0 1.061l-.53.53a.75.75 0 0 1-1.06 0c-4.98-4.979-13.053-4.979-18.032 0a.75.75 0 0 1-1.06 0l-.53-.53a.75.75 0 0 1 0-1.06Zm3.182 3.182c4.1-4.1 10.749-4.1 14.85 0a.75.75 0 0 1 0 1.061l-.53.53a.75.75 0 0 1-1.062 0 8.25 8.25 0 0 0-11.667 0 .75.75 0 0 1-1.06 0l-.53-.53a.75.75 0 0 1 0-1.06Zm3.204 3.182a6 6 0 0 1 8.486 0 .75.75 0 0 1 0 1.061l-.53.53a.75.75 0 0 1-1.061 0 3.75 3.75 0 0 0-5.304 0 .75.75 0 0 1-1.06 0l-.53-.53a.75.75 0 0 1 0-1.06Zm3.182 3.182a1.5 1.5 0 0 1 2.122 0 .75.75 0 0 1 0 1.061l-.53.53a.75.75 0 0 1-1.061 0l-.53-.53a.75.75 0 0 1 0-1.06Z"
                                clip-rule="evenodd" />
                        </svg> -->
                <img
                  class=""
                  width="50"
                  src=" https://cdn-icons-png.flaticon.com/512/9674/9674659.png"
                  alt=""
                />
              </div>
              <div
                class="col-8 d-flex align-items-center justify-content-center"
              >
                <div class="counter-card-text">
                  <h3>{{data.totalOrderShipper}}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="group group2">
        <div class="row">
          <div class="card-body-big px-3 py-2 col-4 custom-border-right">
            <span>Gói 12 tháng</span>
            <div class="row">
              <div class="col-4 counter-card-icon text-right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#C46210"
                  class="size-6"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div
                class="col-8 d-flex align-items-center justify-content-center"
              >
                <div class="counter-card-text">
                  <h3>{{data.totalOrder12M}}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body-big px-3 py-2 col-4 custom-border-right">
            <span>Gói 6 tháng</span>
            <div class="row">
              <div class="col-4 counter-card-icon text-right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#595E4C"
                  class="size-6"
                >
                  <path
                    d="M12 11.993a.75.75 0 0 0-.75.75v.006c0 .414.336.75.75.75h.006a.75.75 0 0 0 .75-.75v-.006a.75.75 0 0 0-.75-.75H12ZM12 16.494a.75.75 0 0 0-.75.75v.005c0 .414.335.75.75.75h.005a.75.75 0 0 0 .75-.75v-.005a.75.75 0 0 0-.75-.75H12ZM8.999 17.244a.75.75 0 0 1 .75-.75h.006a.75.75 0 0 1 .75.75v.006a.75.75 0 0 1-.75.75h-.006a.75.75 0 0 1-.75-.75v-.006ZM7.499 16.494a.75.75 0 0 0-.75.75v.005c0 .414.336.75.75.75h.005a.75.75 0 0 0 .75-.75v-.005a.75.75 0 0 0-.75-.75H7.5ZM13.499 14.997a.75.75 0 0 1 .75-.75h.006a.75.75 0 0 1 .75.75v.005a.75.75 0 0 1-.75.75h-.006a.75.75 0 0 1-.75-.75v-.005ZM14.25 16.494a.75.75 0 0 0-.75.75v.006c0 .414.335.75.75.75h.005a.75.75 0 0 0 .75-.75v-.006a.75.75 0 0 0-.75-.75h-.005ZM15.75 14.995a.75.75 0 0 1 .75-.75h.005a.75.75 0 0 1 .75.75v.006a.75.75 0 0 1-.75.75H16.5a.75.75 0 0 1-.75-.75v-.006ZM13.498 12.743a.75.75 0 0 1 .75-.75h2.25a.75.75 0 1 1 0 1.5h-2.25a.75.75 0 0 1-.75-.75ZM6.748 14.993a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M18 2.993a.75.75 0 0 0-1.5 0v1.5h-9V2.994a.75.75 0 1 0-1.5 0v1.497h-.752a3 3 0 0 0-3 3v11.252a3 3 0 0 0 3 3h13.5a3 3 0 0 0 3-3V7.492a3 3 0 0 0-3-3H18V2.993ZM3.748 18.743v-7.5a1.5 1.5 0 0 1 1.5-1.5h13.5a1.5 1.5 0 0 1 1.5 1.5v7.5a1.5 1.5 0 0 1-1.5 1.5h-13.5a1.5 1.5 0 0 1-1.5-1.5Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div
                class="col-8 d-flex align-items-center justify-content-center"
              >
                <div class="counter-card-text">
                  <h3>{{data.totalOrder6M}}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body-big px-3 py-2 col-4">
            <span>Gói 3 tháng</span>
            <div class="row">
              <div class="col-4 counter-card-icon text-right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#6A397B"
                  class="size-6"
                >
                  <path
                    d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div
                class="col-8 d-flex align-items-center justify-content-center"
              >
                <div class="counter-card-text">
                  <h3>{{data.totalOrder3M}}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="group group1">
        <div class="row">
          <div class="card-body-big px-3 py-2 col-4 custom-border-right">
            <span>4G12M</span>
            <div class="row">
              <div class="col-4 counter-card-icon text-right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#C46210"
                  class="size-6"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div
                class="col-8 d-flex align-items-center justify-content-center"
              >
                <div class="counter-card-text">
                  <h3>{{data.order_4G12m}}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body-big px-3 py-2 col-4 custom-border-right">
            <span>4G6M</span>
            <div class="row">
              <div class="col-4 counter-card-icon text-right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#595E4C"
                  class="size-6"
                >
                  <path
                    d="M12 11.993a.75.75 0 0 0-.75.75v.006c0 .414.336.75.75.75h.006a.75.75 0 0 0 .75-.75v-.006a.75.75 0 0 0-.75-.75H12ZM12 16.494a.75.75 0 0 0-.75.75v.005c0 .414.335.75.75.75h.005a.75.75 0 0 0 .75-.75v-.005a.75.75 0 0 0-.75-.75H12ZM8.999 17.244a.75.75 0 0 1 .75-.75h.006a.75.75 0 0 1 .75.75v.006a.75.75 0 0 1-.75.75h-.006a.75.75 0 0 1-.75-.75v-.006ZM7.499 16.494a.75.75 0 0 0-.75.75v.005c0 .414.336.75.75.75h.005a.75.75 0 0 0 .75-.75v-.005a.75.75 0 0 0-.75-.75H7.5ZM13.499 14.997a.75.75 0 0 1 .75-.75h.006a.75.75 0 0 1 .75.75v.005a.75.75 0 0 1-.75.75h-.006a.75.75 0 0 1-.75-.75v-.005ZM14.25 16.494a.75.75 0 0 0-.75.75v.006c0 .414.335.75.75.75h.005a.75.75 0 0 0 .75-.75v-.006a.75.75 0 0 0-.75-.75h-.005ZM15.75 14.995a.75.75 0 0 1 .75-.75h.005a.75.75 0 0 1 .75.75v.006a.75.75 0 0 1-.75.75H16.5a.75.75 0 0 1-.75-.75v-.006ZM13.498 12.743a.75.75 0 0 1 .75-.75h2.25a.75.75 0 1 1 0 1.5h-2.25a.75.75 0 0 1-.75-.75ZM6.748 14.993a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M18 2.993a.75.75 0 0 0-1.5 0v1.5h-9V2.994a.75.75 0 1 0-1.5 0v1.497h-.752a3 3 0 0 0-3 3v11.252a3 3 0 0 0 3 3h13.5a3 3 0 0 0 3-3V7.492a3 3 0 0 0-3-3H18V2.993ZM3.748 18.743v-7.5a1.5 1.5 0 0 1 1.5-1.5h13.5a1.5 1.5 0 0 1 1.5 1.5v7.5a1.5 1.5 0 0 1-1.5 1.5h-13.5a1.5 1.5 0 0 1-1.5-1.5Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div
                class="col-8 d-flex align-items-center justify-content-center"
              >
                <div class="counter-card-text">
                  <h3>{{data.order_4G6M}}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body-big px-3 py-2 col-4">
            <span>4G3M </span>
            <div class="row">
              <div class="col-4 counter-card-icon text-right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#6A397B"
                  class="size-6"
                >
                  <path
                    d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div
                class="col-8 d-flex align-items-center justify-content-center"
              >
                <div class="counter-card-text">
                  <h3>{{data.order_4G3m}}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="group group2">
        <div class="row">
          <div class="card-body-big px-3 py-2 col-4 custom-border-right">
            <span>WIFI12M </span>
            <div class="row">
              <div class="col-4 counter-card-icon text-right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#C46210"
                  class="size-6"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div
                class="col-8 d-flex align-items-center justify-content-center"
              >
                <div class="counter-card-text">
                  <h3>{{data.order_WIFI12m}}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body-big px-3 py-2 col-4 custom-border-right">
            <span>WIFI6M </span>
            <div class="row">
              <div class="col-4 counter-card-icon text-right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#595E4C"
                  class="size-6"
                >
                  <path
                    d="M12 11.993a.75.75 0 0 0-.75.75v.006c0 .414.336.75.75.75h.006a.75.75 0 0 0 .75-.75v-.006a.75.75 0 0 0-.75-.75H12ZM12 16.494a.75.75 0 0 0-.75.75v.005c0 .414.335.75.75.75h.005a.75.75 0 0 0 .75-.75v-.005a.75.75 0 0 0-.75-.75H12ZM8.999 17.244a.75.75 0 0 1 .75-.75h.006a.75.75 0 0 1 .75.75v.006a.75.75 0 0 1-.75.75h-.006a.75.75 0 0 1-.75-.75v-.006ZM7.499 16.494a.75.75 0 0 0-.75.75v.005c0 .414.336.75.75.75h.005a.75.75 0 0 0 .75-.75v-.005a.75.75 0 0 0-.75-.75H7.5ZM13.499 14.997a.75.75 0 0 1 .75-.75h.006a.75.75 0 0 1 .75.75v.005a.75.75 0 0 1-.75.75h-.006a.75.75 0 0 1-.75-.75v-.005ZM14.25 16.494a.75.75 0 0 0-.75.75v.006c0 .414.335.75.75.75h.005a.75.75 0 0 0 .75-.75v-.006a.75.75 0 0 0-.75-.75h-.005ZM15.75 14.995a.75.75 0 0 1 .75-.75h.005a.75.75 0 0 1 .75.75v.006a.75.75 0 0 1-.75.75H16.5a.75.75 0 0 1-.75-.75v-.006ZM13.498 12.743a.75.75 0 0 1 .75-.75h2.25a.75.75 0 1 1 0 1.5h-2.25a.75.75 0 0 1-.75-.75ZM6.748 14.993a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M18 2.993a.75.75 0 0 0-1.5 0v1.5h-9V2.994a.75.75 0 1 0-1.5 0v1.497h-.752a3 3 0 0 0-3 3v11.252a3 3 0 0 0 3 3h13.5a3 3 0 0 0 3-3V7.492a3 3 0 0 0-3-3H18V2.993ZM3.748 18.743v-7.5a1.5 1.5 0 0 1 1.5-1.5h13.5a1.5 1.5 0 0 1 1.5 1.5v7.5a1.5 1.5 0 0 1-1.5 1.5h-13.5a1.5 1.5 0 0 1-1.5-1.5Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div
                class="col-8 d-flex align-items-center justify-content-center"
              >
                <div class="counter-card-text">
                  <h3>{{data.order_WIFI6M}}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body-big px-3 py-2 col-4">
            <span>WIFI3M </span>
            <div class="row">
              <div class="col-4 counter-card-icon text-right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#6A397B"
                  class="size-6"
                >
                  <path
                    d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div
                class="col-8 d-flex align-items-center justify-content-center"
              >
                <div class="counter-card-text">
                  <h3>{{data.order_WIFI3m}}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="group3">
          <div
            *ngIf="chartOptions"
            class="chart"
            style="
              display: inline-block;
              width: 100%;
              background-color: #fff;
              border-radius: 12px;
              box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
            "
          >
            <div id="chart" class="pt-2" *ngIf="role !== 'operation'">
              <apx-chart
                [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [dataLabels]="chartOptions.dataLabels"
                [plotOptions]="chartOptions.plotOptions"
                [yaxis]="chartOptions.yaxis"
                [xaxis]="chartOptions.xaxis"
                [fill]="chartOptions.fill"
                [title]="chartOptions.title"
              ></apx-chart>
            </div>
          </div>
          <div class="multi-group p-0">
            <div
              class="group"
              style="
                display: inline-block;
                background-color: #fff;
                width: 100%;
                border-radius: 12px;
                box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
              "
            >
              <div class="row">
                <div class="card-body-big px-3 py-2 col-4 custom-border-right">
                  <span>Đơn chờ GHN</span>
                  <div class="row">
                    <div class="col-4 counter-card-icon text-right">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="#486241"
                        class="size-6"
                      >
                        <path
                          d="M3.375 4.5C2.339 4.5 1.5 5.34 1.5 6.375V13.5h12V6.375c0-1.036-.84-1.875-1.875-1.875h-8.25ZM13.5 15h-12v2.625c0 1.035.84 1.875 1.875 1.875h.375a3 3 0 1 1 6 0h3a.75.75 0 0 0 .75-.75V15Z"
                        />
                        <path
                          d="M8.25 19.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0ZM15.75 6.75a.75.75 0 0 0-.75.75v11.25c0 .087.015.17.042.248a3 3 0 0 1 5.958.464c.853-.175 1.522-.935 1.464-1.883a18.659 18.659 0 0 0-3.732-10.104 1.837 1.837 0 0 0-1.47-.725H15.75Z"
                        />
                        <path
                          d="M19.5 19.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z"
                        />
                      </svg>
                    </div>
                    <div
                      class="col-8 d-flex align-items-center justify-content-center"
                    >
                      <div class="counter-card-text">
                        <h3>{{data.totalOrderGHN}}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body-big px-3 py-2 col-4 custom-border-right">
                  <span>Đơn đang giao</span>
                  <div class="row">
                    <div class="col-4 counter-card-icon text-right">
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#F29388"
                                        class="size-6">
                                        <path fill-rule="evenodd"
                                            d="M5.625 1.5H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875ZM9.75 14.25a.75.75 0 0 0 0 1.5H15a.75.75 0 0 0 0-1.5H9.75Z"
                                            clip-rule="evenodd" />
                                        <path
                                            d="M14.25 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 16.5 7.5h-1.875a.375.375 0 0 1-.375-.375V5.25Z" />
                                    </svg> -->
                      <img
                        class=""
                        width="50"
                        src="https://cdn2.iconfinder.com/data/icons/e-commerce-color-6/512/delivery_waiting-512.png"
                        alt=""
                      />
                    </div>
                    <div
                      class="col-8 d-flex align-items-center justify-content-center"
                    >
                      <div class="counter-card-text">
                        <h3>{{data.totalOrderInTransit}}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body-big px-3 py-2 col-4">
                  <span>Đơn đã giao</span>
                  <div class="row">
                    <div class="col-4 counter-card-icon text-right">
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#7CB9E8"
                                        class="size-6">
                                        <path fill-rule="evenodd"
                                            d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625ZM7.5 15a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 7.5 15Zm.75 2.25a.75.75 0 0 0 0 1.5H12a.75.75 0 0 0 0-1.5H8.25Z"
                                            clip-rule="evenodd" />
                                        <path
                                            d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                                    </svg> -->
                      <img
                        class=""
                        width="50"
                        src="https://cdn-icons-png.flaticon.com/512/12964/12964027.png"
                        alt=""
                      />
                    </div>
                    <div
                      class="col-8 d-flex align-items-center justify-content-center"
                    >
                      <div class="counter-card-text">
                        <h3>{{data.totalOrderDelivered}}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="role !== 'operation'"
              class="group"
              style="
                display: inline-block;
                background-color: #fff;
                width: 100%;
                border-radius: 12px;
                box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
              "
            >
              <div class="row">
                <div class="card-body-big px-3 py-2 col-4 custom-border-right">
                  <span>Tổng số loa</span>
                  <div class="row">
                    <div class="col-4 counter-card-icon text-right">
                      <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#71BC68"
                    class="size-6"
                  >
                    <path
                      d="M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5zm2 0v10h14V5H5zm-3 12h20v2H2v-2z"
                    />
                  </svg> -->
                      <img
                        src="assets/images/logo_qrpaybox-bup.png"
                        class="rounded-circle"
                        alt=""
                        width="50"
                      />
                    </div>
                    <div
                      class="col-8 d-flex align-items-center justify-content-center"
                    >
                      <div class="counter-card-text">
                        <h3>{{data.totalDeviceStock}}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body-big px-3 py-2 col-4 custom-border-right">
                  <span>Loa đã bán</span>
                  <div class="row">
                    <div class="col-4 counter-card-icon text-right">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="#B22B27"
                        class="size-6"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M3.75 3.375c0-1.036.84-1.875 1.875-1.875H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375Zm10.5 1.875a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 16.5 7.5h-1.875a.375.375 0 0 1-.375-.375V5.25ZM12 10.5a.75.75 0 0 1 .75.75v.028a9.727 9.727 0 0 1 1.687.28.75.75 0 1 1-.374 1.452 8.207 8.207 0 0 0-1.313-.226v1.68l.969.332c.67.23 1.281.85 1.281 1.704 0 .158-.007.314-.02.468-.083.931-.83 1.582-1.669 1.695a9.776 9.776 0 0 1-.561.059v.028a.75.75 0 0 1-1.5 0v-.029a9.724 9.724 0 0 1-1.687-.278.75.75 0 0 1 .374-1.453c.425.11.864.186 1.313.226v-1.68l-.968-.332C9.612 14.974 9 14.354 9 13.5c0-.158.007-.314.02-.468.083-.931.831-1.582 1.67-1.694.185-.025.372-.045.56-.06v-.028a.75.75 0 0 1 .75-.75Zm-1.11 2.324c.119-.016.239-.03.36-.04v1.166l-.482-.165c-.208-.072-.268-.211-.268-.285 0-.113.005-.225.015-.336.013-.146.14-.309.374-.34Zm1.86 4.392V16.05l.482.165c.208.072.268.211.268.285 0 .113-.005.225-.015.336-.012.146-.14.309-.374.34-.12.016-.24.03-.361.04Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div
                      class="col-8 d-flex align-items-center justify-content-center"
                    >
                      <div class="counter-card-text">
                        <h3>{{data.totalDeviceSold}}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body-big px-3 py-2 col-4">
                  <span>Loa tồn kho</span>
                  <div class="row">
                    <div class="col-4 counter-card-icon text-right">
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#C871D2"
                                        class="size-6">
                                        <path fill-rule="evenodd"
                                            d="M5.625 1.5H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875ZM9.75 17.25a.75.75 0 0 0-1.5 0V18a.75.75 0 0 0 1.5 0v-.75Zm2.25-3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0v-3a.75.75 0 0 1 .75-.75Zm3.75-1.5a.75.75 0 0 0-1.5 0V18a.75.75 0 0 0 1.5 0v-5.25Z"
                                            clip-rule="evenodd" />
                                        <path
                                            d="M14.25 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 16.5 7.5h-1.875a.375.375 0 0 1-.375-.375V5.25Z" />
                                    </svg> -->
                      <img
                        class=""
                        width="50"
                        src="https://cdn-icons-png.flaticon.com/256/407/407826.png"
                        alt=""
                      />
                    </div>
                    <div
                      class="col-8 d-flex align-items-center justify-content-center"
                    >
                      <div class="counter-card-text">
                        <h3>{{data.inventoryDevice}}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="group"
              style="
                display: inline-block;
                background-color: #fff;
                width: 100%;
                border-radius: 12px;
                box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
              "
            >
              <div class="row">
                <div class="card-body-big px-3 py-2 col-4 custom-border-right">
                  <span>Tổng số cửa hàng</span>
                  <div class="row">
                    <div class="col-4 counter-card-icon text-right">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="size-6"
                      >
                        <path
                          d="M5.223 2.25c-.497 0-.974.198-1.325.55l-1.3 1.298A3.75 3.75 0 0 0 7.5 9.75c.627.47 1.406.75 2.25.75.844 0 1.624-.28 2.25-.75.626.47 1.406.75 2.25.75.844 0 1.623-.28 2.25-.75a3.75 3.75 0 0 0 4.902-5.652l-1.3-1.299a1.875 1.875 0 0 0-1.325-.549H5.223Z"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M3 20.25v-8.755c1.42.674 3.08.673 4.5 0A5.234 5.234 0 0 0 9.75 12c.804 0 1.568-.182 2.25-.506a5.234 5.234 0 0 0 2.25.506c.804 0 1.567-.182 2.25-.506 1.42.674 3.08.675 4.5.001v8.755h.75a.75.75 0 0 1 0 1.5H2.25a.75.75 0 0 1 0-1.5H3Zm3-6a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75v-3Zm8.25-.75a.75.75 0 0 0-.75.75v5.25c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75v-5.25a.75.75 0 0 0-.75-.75h-3Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div
                      class="col-8 d-flex align-items-center justify-content-center"
                    >
                      <div class="counter-card-text">
                        <h3>{{data.totalWorkspaces}}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body-big px-3 py-2 col-4 custom-border-right">
                  <span>Tổng số giao dịch QR</span>
                  <div class="row">
                    <div class="col-4 counter-card-icon text-right">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="#D7B5A0"
                        class="size-6"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M3 4.875C3 3.839 3.84 3 4.875 3h4.5c1.036 0 1.875.84 1.875 1.875v4.5c0 1.036-.84 1.875-1.875 1.875h-4.5A1.875 1.875 0 0 1 3 9.375v-4.5ZM4.875 4.5a.375.375 0 0 0-.375.375v4.5c0 .207.168.375.375.375h4.5a.375.375 0 0 0 .375-.375v-4.5a.375.375 0 0 0-.375-.375h-4.5Zm7.875.375c0-1.036.84-1.875 1.875-1.875h4.5C20.16 3 21 3.84 21 4.875v4.5c0 1.036-.84 1.875-1.875 1.875h-4.5a1.875 1.875 0 0 1-1.875-1.875v-4.5Zm1.875-.375a.375.375 0 0 0-.375.375v4.5c0 .207.168.375.375.375h4.5a.375.375 0 0 0 .375-.375v-4.5a.375.375 0 0 0-.375-.375h-4.5ZM6 6.75A.75.75 0 0 1 6.75 6h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75A.75.75 0 0 1 6 7.5v-.75Zm9.75 0A.75.75 0 0 1 16.5 6h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75ZM3 14.625c0-1.036.84-1.875 1.875-1.875h4.5c1.036 0 1.875.84 1.875 1.875v4.5c0 1.035-.84 1.875-1.875 1.875h-4.5A1.875 1.875 0 0 1 3 19.125v-4.5Zm1.875-.375a.375.375 0 0 0-.375.375v4.5c0 .207.168.375.375.375h4.5a.375.375 0 0 0 .375-.375v-4.5a.375.375 0 0 0-.375-.375h-4.5Zm7.875-.75a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Zm6 0a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75ZM6 16.5a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Zm9.75 0a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Zm-3 3a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Zm6 0a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div
                      class="col-8 d-flex align-items-center justify-content-center"
                    >
                      <div class="counter-card-text">
                        <h3>{{data.totalTransactions}}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body-big px-3 py-2 col-4">
                  <span>Tổng giá trị giao dịch</span>
                  <div class="row" style="height: 40px;">
                    <!-- <div class="col-2 counter-card-icon text-right">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FF5A5F"
                                        class="size-6">
                                        <path
                                            d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 0 1-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004ZM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 0 1-.921.42Z" />
                                        <path fill-rule="evenodd"
                                            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v.816a3.836 3.836 0 0 0-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 0 1-.921-.421l-.879-.66a.75.75 0 0 0-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 0 0 1.5 0v-.81a4.124 4.124 0 0 0 1.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 0 0-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 0 0 .933-1.175l-.415-.33a3.836 3.836 0 0 0-1.719-.755V6Z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </div> -->
                    <div class="col-12 d-flex align-items-center">
                      <div class="counter-card-text">
                        <div class="total-amount font-weight-bold">
                          {{ data.totalAmount | number:'1.0-0' }} VNĐ
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="chartOptionsQR" class="chart group4">
          <div
            style="
              background-color: #fff;
              border-radius: 12px;
              height: 100%;
              box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
            "
          >
            <div id="chart" class="pt-2">
              <apx-chart
                [series]="chartOptionsQR.series"
                [chart]="chartOptionsQR.chart"
                [yaxis]="chartOptionsQR.yaxis"
                [xaxis]="chartOptionsQR.xaxis"
                [labels]="chartOptionsQR.labels"
                [stroke]="chartOptionsQR.stroke"
                [title]="chartOptionsQR.title"
                [dataLabels]="chartOptionsQR.dataLabels"
                [fill]="chartOptionsQR.fill"
                [tooltip]="chartOptionsQR.tooltip"
              ></apx-chart>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="chartOptionsService" class="chart group5">
        <div id="chart" class="pt-2">
          <apx-chart
            [series]="chartOptionsService.series"
            [chart]="chartOptionsService.chart"
            [dataLabels]="chartOptionsService.dataLabels"
            [plotOptions]="chartOptionsService.plotOptions"
            [yaxis]="chartOptionsService.yaxis"
            [legend]="chartOptionsService.legend"
            [fill]="chartOptionsService.fill"
            [stroke]="chartOptionsService.stroke"
            [tooltip]="chartOptionsService.tooltip"
            [xaxis]="chartOptionsService.xaxis"
            [title]="chartOptionsService.title"
          ></apx-chart>
        </div>
      </div>
    </div>
  </ng-template>
</div>
