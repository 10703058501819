import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/core/services/event.service';
import { ApiService } from 'src/app/shared/services/api.service';

interface DataTest {
  full_name: string
}

@Component({
  selector: 'app-details-transactions',
  templateUrl: './details-transactions.component.html',
  styleUrls: ['./details-transactions.component.scss']
})
export class DetailsTransactionsComponent implements OnInit {

  data = <any>{}
  id: number = 0;
  // eventService: any;
  pageSize = 10
  pageIndex = 1
  totalCount = 2

  tableName: string = 'transactions/';
  constructor(
    public eventService: EventService,
    private apiService: ApiService,
    public activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['id'];
    // console.log(this.id);
    // console.log(this.listData);

    // this.route.params.subscribe(params => {
    //   let id = Number.parseInt(params['id']);
    //   this.person = this.peopleService.get(id);
    // });
    this.fetchData();
  }
  fetchData() {

    // params = '&order=updated_at&direction=desc'
    this.eventService.setLoading(true)
    this.apiService.getList(this.tableName + this.id
      // + `?page=${this.pageIndex}&limit=${this.pageSize}${params}`
    ).subscribe({
      next: (res) => {
        this.data = res
        console.log(this.data)
        this.totalCount = res.length
        // // this.totalCount = res.meta.total
        this.eventService.setLoading(false)
      }
    })
  }

}
