import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-vertical-Cs',
  templateUrl: './vertical.component.html',
  styleUrls: ['./vertical.component.scss']
})
export class VerticalCsComponent implements OnInit {
  url: string;
  CsFooterFlag: boolean = true;

  constructor(
    @Inject(Router) private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {

    document.body.setAttribute('data-sidebar', 'dark');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-layout');
    document.body.removeAttribute('data-topbar');
    document.body.classList.remove('auth-body-bg');

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
        this.updateFooterFlag(this.url);
      }
    });

    this.url = this.router.url;
    this.updateFooterFlag(this.url);
  }

  updateFooterFlag(url: string) {
    const noFooterRoutes = ['/detail-shop', '/create-device', '/create-qr', '/device-detail', '/transactions', '/payment'];
    this.CsFooterFlag = !noFooterRoutes.some(route => url.includes(route));

    // if (url.includes('/cs/menu')) {
    //   document.body.classList.remove('page-content');
    //   console.log(1);

    // } else {
    //   document.body.classList.add('page-content');
    // }
  }
  /**
   * On Cs toggle button clicked
   */
  onToggleCsMenu() {
    document.body.classList.toggle('sidebar-enable');
    document.body.classList.toggle('vertical-collpsed');

    if (window.screen.width <= 768) {
      document.body.classList.remove('vertical-collpsed');
    }
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }
}
