import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnChanges, EventEmitter, Output, Renderer2 } from '@angular/core';
import MetisMenu from 'metismenujs/dist/metismenujs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { EventService } from '../../../core/services/event.service';
import Swal from 'sweetalert2';
import { AuthfakeauthenticationService } from 'src/app/core/services/authfake.service';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit, AfterViewInit {

  menu: any;
  role = JSON.parse(localStorage.getItem('currentUser')).role;
  menuItems = [];

  @ViewChild('sideMenu') sideMenu: ElementRef;
  @Output() mobileMenuButtonClicked = new EventEmitter();

  constructor(
    private eventService: EventService,
    private router: Router,
    private renderer: Renderer2,
    public authFackservice: AuthfakeauthenticationService,
    private translateService: TranslateService,
    private route: ActivatedRoute) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
      }
    });
  }

  ngOnInit(): void {
    this.initialize();

    // console.log(this.role)
  }

  ngAfterViewInit() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);
    this._activateMenuDropdown();
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  findLatestPath(paths, path, count) {
    if (count < 0) return;

    const index = paths.indexOf(path)
    if (index >= 0) {
      return index;
    } else {
      const strIndex = path.lastIndexOf('/');
      const item = path.substr(0, strIndex).toString();
      return this.findLatestPath(paths, item, count - 1)
    }

  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    this._removeAllClass('mm-active');
    this._removeAllClass('mm-show');
    const links = document.getElementsByClassName('side-nav-link-ref');
    let menuItemEl = null;
    const paths = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      paths.push(links[i]['hash']);
      // paths.push(links[i]['pathname']);
    }
    const itemIndex = paths.indexOf(window.location.hash);
    if (itemIndex === -1) {
      // const strIndex = window.location.hash.lastIndexOf('/');
      // const item = window.location.hash.substr(0, strIndex).toString();
      const index = this.findLatestPath(paths, window.location.hash, paths.length)

      menuItemEl = links[index];
    } else {
      menuItemEl = links[itemIndex];
    }
    if (menuItemEl) {
      menuItemEl.classList.add('active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.add('mm-active');

        const parent2El = parentEl.parentElement.closest('ul');
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.add('mm-show');
          const parent3El = parent2El.parentElement;

          if (parent3El && parent3El.id !== 'side-menu') {
            parent3El.classList.add('mm-active');
            const childAnchor = parent3El.querySelector('.has-arrow');
            const childDropdown = parent3El.querySelector('.has-dropdown');

            if (childAnchor) { childAnchor.classList.add('mm-active'); }
            if (childDropdown) { childDropdown.classList.add('mm-active'); }

            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== 'side-menu') {
              parent4El.classList.add('mm-show');
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== 'side-menu') {
                parent5El.classList.add('mm-active');
                const childanchor = parent5El.querySelector('.is-parent');
                if (childanchor && parent5El.id !== 'side-menu') { childanchor.classList.add('mm-active'); }
              }
            }
          }
        }
      }
    }
  }
  //backToApp
  onBack() {
    Swal.fire({
      // title: this.translateService.instant('FORM.Warning'),
      text: this.translateService.instant('Bạn có chắc chắn muốn đóng ứng dụng?'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#ff3d60',
      confirmButtonText: this.translateService.instant('FORM.WarningConfirm'),
      cancelButtonText: this.translateService.instant('FORM.Cancel'),
      customClass: {
        icon: 'my-custom-icon-class'
      }
    }).then(result => {
      if (result.value) {
        this.authFackservice.logoutMobile();

        setTimeout(() => {
          if (window['ReactNativeWebView']) {
            window['ReactNativeWebView'].postMessage(JSON.stringify({
              type: 'GO_BACK',
            }));
            console.log('Message posted to React Native WebView');
          } else {
            console.warn('ReactNativeWebView không tồn tại. Sử dụng môi trường local để test.');
            window['ReactNativeWebView'] = {
              postMessage: (message) => {
                console.log('Message posted to React Native (mock):', message);
              }
            };
            window['ReactNativeWebView'].postMessage(JSON.stringify({
              type: 'GO_BACK',
            }));
          }
        }, 2000);
      }
    });

  }



  /**
   * Initialize
   */
  initialize(): void {
    this.menuItems = MENU;
  }
  showAlert(message: string): void {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
    });
    Toast.fire({
      icon: "warning",
      title: message
    });
  }
  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  /**
   * Change the layout onclick
   * @param layout Change the layout
   */
  changeLayout(layout: string) {
    this.eventService.broadcast('changeLayout', layout);
  }

  /**
   * Light sidebar
   */
  lightSidebar() {
    document.body.setAttribute('data-sidebar', 'light');
    document.body.setAttribute('data-topbar', 'dark');
    document.body.removeAttribute('data-sidebar-size');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-keep-enlarged');
    document.body.classList.remove('vertical-collpsed');
  }

  /**
   * Compact sidebar
   */
  compactSidebar() {
    document.body.setAttribute('data-sidebar-size', 'small');
    document.body.setAttribute('data-sidebar', 'dark');
    document.body.removeAttribute('data-topbar');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-keep-enlarged');
    document.body.classList.remove('sidebar-enable');
    document.body.classList.remove('vertical-collpsed');
  }

  /**
   * Icon sidebar
   */
  iconSidebar() {
    document.body.classList.add('sidebar-enable');
    document.body.classList.add('vertical-collpsed');
    document.body.setAttribute('data-sidebar', 'dark');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-keep-enlarged');
    document.body.removeAttribute('data-topbar');
  }

  /**
   * Boxed layout
   */
  boxedLayout() {
    document.body.setAttribute('data-keep-enlarged', 'true');
    document.body.setAttribute('data-layout-size', 'boxed');
    document.body.setAttribute('data-sidebar', 'dark');
    document.body.classList.add('vertical-collpsed');
    document.body.classList.remove('sidebar-enable');
    document.body.removeAttribute('data-topbar');
  }

  /**
   * Colored sidebar
   */
  coloredSidebar() {
    document.body.setAttribute('data-sidebar', 'colored');
    document.body.removeAttribute('data-sidebar-size');
    document.body.removeAttribute('data-layout-size');
    document.body.classList.remove('vertical-collpsed');
    document.body.removeAttribute('data-topbar');
  }
  navigateAndHideSidebar() {
    document.body.classList.remove('sidebar-enable');
  }
}
