<!-- vertical layout -->
<app-vertical-Cs *ngIf="isVerticalLayoutRequested()"></app-vertical-Cs>
<!-- <app-vertical-Cs> </app-vertical-Cs> -->
<!-- horizontal layout -->
<app-horizontal-Cs *ngIf="isHorizontalLayoutRequested()"></app-horizontal-Cs>

<div
  class="modal-backdrop show d-flex justify-content-center align-items-center"
  style="z-index: 9999;"
  *ngIf="loading|async"
>
  <div class="text-center">
    <!-- <div class="spinner-border text-white" style="width: 4rem; height: 4rem" role="status"> -->
    <!-- <span class="sr-only">Loading...</span> -->
    <!-- </div> -->
    <img
      src="https://cdn.haitrieu.com/wp-content/uploads/2022/02/Icon-MB-Bank-MBB.png"
      alt=""
      width="50"
      class="spin"
    />

    <button class="btn btn-primary mt-2 mx-2 d-block" (click)="offLoading()">
      Hủy
    </button>
  </div>
</div>
