<footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          Giải pháp được phát triển bởi TOMOTEK
          <!-- 2024 © TOMOTEK QRPay * Một sản phẩm số của hệ sinh thái MB -->
        </div>
        <!-- <div class="col-sm-6">
          <div class="text-sm-right d-none d-sm-block">
            Crafted with <i class="mdi mdi-heart text-danger"></i> by Themesdesign
          </div>
        </div> -->
      </div>
    </div>
  </footer>
  