<div class="card">
    <div class="card-body">
      <h5>{{'Đơn chờ Viettel Post' | translate}}</h5>
  
      <div class="">
        <div class="row d-flex">
          <div class="form-group col-3">
            <label for="validationCustom01"
              >{{ 'Chọn trạng thái' | translate }}</label
            >
            <ng-select
              [(ngModel)]="filter.status"
              [clearable]="true"
              (change)="submitFilter()"
            >
              <ng-option value="" hidden
                >{{ 'Trạng thái giao hàng' | translate }}</ng-option
              >
              <ng-option *ngFor="let item of listStatus" [value]="item.value"
                >{{ item.label }}</ng-option
              >
            </ng-select>
          </div>
          <div class="form-group col-3">
            <label for="validationCustom01"
              >{{ 'Số tài khoản' | translate }}</label
            >
            <input
              type="tel"
              class="form-control"
              [(ngModel)]="filter.terminal_bank_acc"
              placeholder="Nhập số tài khoản"
              (change)="submitFilter()"
            />
          </div>
          <div class="form-group col-3">
            <label for="validationCustom01"
              >{{ 'Số điện thoại' | translate }}</label
            >
            <input
              type="tel"
              class="form-control"
              [(ngModel)]="filter.phone"
              placeholder="Nhập số điện thoại"
              (change)="submitFilter()"
            />
          </div>
          <div class="form-group col-3">
            <label for="validationCustom01"
              >{{ 'Số điện thoại người nhận' | translate }}</label
            >
            <input
              type="tel"
              class="form-control"
              [(ngModel)]="filter.recipientPhone"
              placeholder="Nhập số điện thoại người nhận"
              (change)="submitFilter()"
            />
          </div>
          <div class="form-group col-3">
            <label for="validationCustom01"
              >{{ 'Mã thiết bị' | translate }}</label
            >
            <input
              type="text"
              class="form-control"
              [(ngModel)]="filter.serial_number"
              placeholder="Nhập mã thiết bị"
              (change)="submitFilter()"
            />
          </div>
          <div class="form-group col-3">
            <label for="validationCustom01"
              >{{ 'Mã giới thiệu' | translate }}</label
            >
            <input
              type="text"
              class="form-control"
              [(ngModel)]="filter.referrer"
              placeholder="Nhập mã giới thiệu"
              (change)="submitFilter()"
            />
          </div>
          <div class="form-group col-3">
            <label for="validationCustom01"
              >{{ 'Loại thiết bị' | translate }}</label
            >
            <ng-select
              [(ngModel)]="filter.device_type"
              [clearable]="true"
              (change)="submitFilter()"
            >
              <ng-option value="" hidden
                >{{ 'Chọn loại thiết bị' | translate }}</ng-option
              >
              <ng-option
                *ngFor="let item of listDevicesFilter"
                [value]="item.value"
                >{{ item.label }}</ng-option
              >
            </ng-select>
          </div>
          <div class="form-group col-3">
            <label for="validationCustom01"
              >{{ 'Loại đơn hàng' | translate }}</label
            >
            <ng-select
              [(ngModel)]="filter.pair_device"
              [clearable]="true"
              (change)="submitFilter()"
            >
              <ng-option value="" hidden
                >{{ 'Chọn loại đơn hàng' | translate }}</ng-option
              >
              <ng-option *ngFor="let item of listTypeOrder" [value]="item.value"
                >{{ item.label }}</ng-option
              >
            </ng-select>
          </div>
          <div class="form-group col-3">
            <mat-form-field appearance="fill" class="mt-3">
              <mat-label>Chọn khoảng ngày </mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input
                  matStartDate
                  formControlName="start"
                  placeholder="Ngày bắt đầu"
                />
                <input
                  matEndDate
                  formControlName="end"
                  placeholder="Ngày kết thúc"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
              <mat-error
                *ngIf="range.controls.start.hasError('matStartDateInvalid')"
                >Invalid start date</mat-error
              >
              <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
                >Invalid end date</mat-error
              >
            </mat-form-field>
          </div>
          <div class="form-group col-3">
            <div>
              <div class="form-group">
                <button class="btn btn-primary" (click)="submitFilter()">
                  {{'PAGE.REPORT.Search' | translate}}
                </button>
                <button
                  class="btn btn-dark ml-2"
                  *ngIf="searching"
                  (click)="clearFilter()"
                >
                  {{'FORM.Clear' | translate}}
                </button>
              </div>
            </div>
          </div>
          <div class="form-group col-3">
            <div>
              <div class="form-group">
                <div *ngIf="role !== 'customer service'">
                  <div
                    class="form-group d-flex ml-2"
                    *ngIf="role !== 'operation'"
                  >
                    <div class="text-left">
                      <button
                        class="btn btn-outline-success"
                        (click)="exportExel()"
                      >
                        Xuất báo cáo
                      </button>
                    </div>
                    <div class="text-left ml-4">
                      <button
                        class="btn btn-outline-primary"
                        (click)="exportPDF()"
                      >
                        Xuất PDF QR
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right mr-4">
        <h5>Tổng đơn: {{totalCount}}</h5>
      </div>
      <div class="table-responsive">
        <table
          id="basic-datatable"
          class="table table-bordered dt-responsive nowrap no-footer dtr-inline"
        >
          <thead>
            <tr class="bg-table">
              <th>#</th>
              <!-- <th></th> -->
              <th width="100" class="table-column" *ngIf="role !== 'operation'">
                {{'Quản lý' | translate}}
              </th>
              <th>{{'Tên cửa hàng' | translate}}</th>
              <th>{{'Số tài khoản' | translate}}</th>
              <th>{{'Chủ TK' | translate}}</th>
              <th>{{'SĐT Khách' | translate}}</th>
              <th>{{'Mã đơn' | translate}}</th>
              <th>{{'Mã TB' | translate}}</th>
              <th>{{'Người nhận' | translate}}</th>
              <th>{{'SĐT người nhận' | translate}}</th>
              <th>{{'Loại TB' | translate}}</th>
              <th>{{'Địa chỉ nhận' | translate}}</th>
              <th>{{'Số TB' | translate}}</th>
              <th>{{'Gói DV' | translate}}</th>
              <th>{{'Trạng thái giao' | translate}}</th>
              <th>{{'Số tiền' | translate}}</th>
              <th>{{'Ngày tạo' | translate}}</th>
              <!-- <th>{{'Ngày cập nhật' | translate}}</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of listData;let i=index">
              <td class="table-column">{{pageSize*(pageIndex-1) +i+1}}</td>
              <td class="py-2 table-column" *ngIf="role !== 'operation'">
                <div
                  class="d-flex"
                  style="gap: 5px;"
                  *ngIf="role == 'customer service' ; else noCustomer"
                >
                  <a
                    class="font-size-18 text-primary"
                    ngbTooltip="{{'Chi tiết' | translate}}"
                    placement="left"
                    (click)="viewDetails(data)"
                  >
                    <i class="mdi mdi-eye"></i>
                  </a>
                </div>
                <ng-template #noCustomer>
                  <a
                    *ngIf="data.serial_number.length > 0"
                    (click)="onEdit(data, contentQR)"
                    class="font-size-18 text-success"
                    ngbTooltip="{{'Hiển thi QR' | translate}}"
                    placement="left"
                  >
                    <i class="mdi mdi-qrcode-scan"></i>
                  </a>
                  <a
                    class="font-size-18 text-success"
                    *ngIf="data.status_eng == 'processing' && data.delivery_method !== 'direct' || data.status_eng == 'confirmed' && data.delivery_method !== 'direct'"
                    ngbTooltip="{{'Liên kết' | translate}}"
                    placement="left"
                    (click)="onLink(data, contentLink)"
                  >
                    <i class="ri-links-line"></i>
                  </a>
                  <a
                    class="font-size-18 text-waning"
                    *ngIf="data.status_eng == 'complete' && data.delivery_method !== 'direct'"
                    ngbTooltip="{{'Tạo đơn' | translate}}"
                    placement="left"
                    (click)="handleOrder(data)"
                  >
                    <i class="ri-luggage-cart-line"></i>
                  </a>
                  <a
                    class="font-size-18 text-primary"
                    ngbTooltip="{{'Chi tiết' | translate}}"
                    placement="left"
                    (click)="viewDetails(data)"
                  >
                    <i class="mdi mdi-eye"></i>
                  </a>
                </ng-template>
              </td>
              <td class="table-column hyperlink" (click)="viewDetails(data)">
                {{data.workspace_name}}
              </td>
              <td class="table-column">{{data.terminal_bank_acc}}</td>
              <td class="table-column-name">
                {{data.terminal_bank_acc_name}}
              </td>
              <td class="table-column-name">
                {{data.user_phone}}
              </td>
              <td class="table-column">
                {{data.order_code ? data.order_code : 'Chưa có '}}
              </td>
              <td class="table-column">
                {{data.serial_number.length > 0 ? data.serial_number : 'Chưa có
                '}}
              </td>
              <td class="table-column">
                {{data.customer_name ? data.customer_name : 'Chưa có '}}
              </td>
              <td class="table-column">
                {{data.phone ? data.phone : 'Đơn trực tiếp'}}
              </td>
              <td class="table-column">
                <span
                  class="font-size-12 badge badge-warning text-danger"
                  *ngIf="data.device_name == 'Loa 4G'; else wifitemp"
                  >{{data.device_name}}</span
                >
                <ng-template #wifitemp>
                  {{data.device_name}}
                </ng-template>
              </td>
              <td class="table-column">
                {{data.to_province_name }}
              </td>
              <td class="table-column">{{data.quantity}}</td>
  
              <td class="table-column">
                <span class="font-size-12" *ngIf="data.original_duration == 3"
                  >{{'Gói 3 tháng' | translate}}</span
                >
                <span class="font-size-12" *ngIf="data.original_duration == 6"
                  >{{'Gói 6 tháng' | translate}}</span
                >
  
                <span *ngIf="data.original_duration == 12" class="font-size-12"
                  >{{ 'Gói 12 tháng'| translate}}</span
                >
              </td>
              <td class="table-column">
                <span class="font-size-12">{{data.status | translate}}</span>
              </td>
              <td class="table-column">
                {{data?.amount | number:'1.0-0'}} <sup><ins>đ</ins></sup>
              </td>
  
              <td class="table-column">
                {{data.created_at | date:'dd/MM/yyyy HH:mm'}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row justify-content-md-between align-items-md-center mt-2">
        <div class="col-sm-12 col-md-5">
          <div
            class="dataTables_info mb-2"
            id="tickets-table_info"
            role="status"
            aria-live="polite"
          >
            {{'TABLE.Showing' | translate}} {{pageSize*pageIndex - pageSize + 1}}
            {{'TABLE.to' | translate}} {{pageSize*pageIndex}} {{'TABLE.of' |
            translate}} {{totalCount}} {{'TABLE.entries' | translate}}
          </div>
        </div>
        <!-- Pagination -->
        <div
          class="col-sm-12 col-md-5 d-flex align-items-center justify-content-end"
        >
          <div class="text-md-right float-md-right pagination-rounded">
            <ngb-pagination
              [collectionSize]="totalCount"
              [(page)]="pageIndex"
              [pageSize]="pageSize"
              [maxSize]="5"
              [ellipses]="true"
              (pageChange)="fetchData()"
            >
            </ngb-pagination>
          </div>
          <div class="dataTables_length ml-3" id="tickets-table_length">
            <label class="d-inline-flex align-items-center mb-0">
              {{'TABLE.Show' | translate}}
              <select
                name="tickets-table_length"
                aria-controls="tickets-table"
                name="pageSize"
                [(ngModel)]="pageSize"
                (ngModelChange)="pageIndex = 1;fetchData()"
                class="custom-select custom-select-sm form-control form-control-sm mx-2"
              >
                <option [ngValue]="10">10</option>
                <option [ngValue]="25">25</option>
                <option [ngValue]="50">50</option>
                <option [ngValue]="100">100</option>
              </select>
              {{'TABLE.entries' | translate}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <ng-template #content role="document" let-modal="close">
    <div class="modal-header">
      <h5 class="modal-title">
        {{(formData.value.id ? "FORM.Edit" : "FORM.AddNew") | translate}}
      </h5>
      <!-- <div *ngIf="makeForm('password').hasError('required')" class="error-message">
                  Mật khẩu không được bỏ trống.
              </div> -->
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-3">
      <div class="text-left">
        <form (ngSubmit)="saveData()" [formGroup]="formData">
          <div class="row">
            <!-- <div class="form-group row col-md-6">
                              <label class="col-md-2 col-form-label required_label">{{'Imei' | translate}}</label>
                              <div class="col-md-10">
                                  <input type="text" class="form-control" formControlName="imei"
                                      [ngClass]="{'is-invalid': submitted && form.imei.errors}" />
                                
                              </div>
                          </div> -->
            <!-- <div class="form-group row col-md-12" *ngIf="!formData.value.id"> -->
  
            <div class="form-group row col-md-12">
              <label class="col-md-2 col-form-label required_label"
                >{{'Serial' | translate}}</label
              >
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  formControlName="serial_number"
                  [ngClass]="{'is-invalid': submitted && form.serial_number.errors}"
                />
              </div>
            </div>
            <div class="form-group row col-md-12">
              <label class="col-md col-form-label required_label"
                >{{'Model' | translate}}</label
              >
              <div class="col-md-10">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  class="form-control"
                  formControlName="model"
                  [ngClass]="{'is-invalid': submitted && form.model.errors}"
                >
                  <option value="{{item}}" *ngFor="let item of listModel"
                    >{{item}}</option
                  >
                </select>
                <!-- <input type="text" class="form-control" formControlName="model"
                                      [ngClass]="{'is-invalid': submitted && form.model.errors}" />
                                 -->
              </div>
            </div>
  
            <div class="form-group row col-md-12">
              <label class="col-md-2 col-form-label required_label"
                >{{'Phiên bản' | translate}}</label
              >
              <div class="col-md-10">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  class="form-control"
                  formControlName="version"
                  [ngClass]="{'is-invalid': submitted && form.version.errors}"
                >
                  <option value="{{item}}" *ngFor="let item of listVersion"
                    >{{item}}</option
                  >
                </select>
              </div>
            </div>
            <!-- <div class="form-group row col-md-12">
                              <label class="col-md-2 col-form-label required_label">{{'Trạng thái' | translate}}</label>
                              <div class="col-md-10">
                                  <select class="form-select" aria-label="Default select example" class="form-control" formControlName="status"  [ngClass]="{'is-invalid': submitted && form.status.errors}">
                                      <option value="0" >Ngoại tuyến</option>
                                      <option value="1" >Trực tuyến</option>
                                  </select>
                              </div>
                          </div>   -->
          </div>
  
          <div class="text-center">
            <button
              type="button"
              class="btn btn-secondary btn-lg mr-3"
              style="width: 150px;"
              (click)="modal('close click')"
            >
              {{'Đóng' | translate}}
            </button>
            <button
              type="submit"
              class="btn btn-success btn-lg"
              style="width: 150px;"
            >
              {{'Lưu' | translate}}
            </button>
            <!-- <button type="button" class="btn btn-secondary btn-sm" style="width: 150px"
                          (click)="modal('close click')">{{'FORM.Close' | translate}}</button>
                      <button type="button" class="btn btn-primary btn-sm ml-3" style="width: 150px"
                          (click)="updateFormNote()">{{'FORM.Save' | translate}}</button> -->
          </div>
        </form>
      </div>
    </div>
  </ng-template>
  
  <ng-template #contentLink role="document" let-modal="close">
    <div class="modal-header">
      <h5 class="modal-title">
        Liên kết thiết bị với đơn hàng
      </h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-3">
      <div class="text-left">
        <form (ngSubmit)="deviceLink(formData.value)" [formGroup]="formData">
          <div class="row">
            <div class="form-group row col-md-12">
              <label class="col-md-2 col-form-label required_label"
                >{{'Đơn hàng' | translate}}</label
              >
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  formControlName="data"
                  [ngClass]="{'is-invalid': submitted && form.data.errors}"
                  disabled
                />
              </div>
            </div>
  
            <div class="form-group row col-md-12">
              <label class="col-md-2 col-form-label required_label"
                >{{'Số thiết bị cần gán' | translate}}</label
              >
              <div class="col-md-10">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  formControlName="quantity"
                  [ngClass]="{'is-invalid': submitted && form.quantity.errors}"
                />
              </div>
            </div>
            <div class="form-group row col-md-12">
              <label class="col-md-2 col-form-label required_label"
                >{{'Thiết bị' | translate}}</label
              >
              <div class="col-md-10">
                <ng-select
                  [items]="listDevices"
                  bindLabel="serial_number"
                  bindValue="serial_number"
                  formControlName="serial_number"
                  [maxSelectedItems]="formData.get('quantity').value"
                  [multiple]="true"
                  [searchable]="true"
                  (search)="onSearchChange($event)"
                  placeholder="{{ 'Chọn thiết bị' | translate }}"
                  [ngClass]="{'is-invalid': submitted && form.serial_number.errors}"
                >
                </ng-select>
              </div>
            </div>
          </div>
  
          <div class="text-center">
            <button
              type="button"
              class="btn btn-secondary btn-lg mr-3"
              style="width: 150px;"
              (click)="modal('close click')"
            >
              {{'Đóng' | translate}}
            </button>
            <button
              type="submit"
              class="btn btn-success btn-lg"
              style="width: 150px;"
            >
              {{'Lưu' | translate}}
            </button>
            <!-- <button type="button" class="btn btn-secondary btn-sm" style="width: 150px"
                          (click)="modal('close click')">{{'FORM.Close' | translate}}</button>
                      <button type="button" class="btn btn-primary btn-sm ml-3" style="width: 150px"
                          (click)="updateFormNote()">{{'FORM.Save' | translate}}</button> -->
          </div>
        </form>
      </div>
    </div>
  </ng-template>
  
  <ng-template #contentDetail role="document" let-modal="close">
    <div class="modal-header">
      <h5 class="modal-title">
        Chi tiết đơn hàng Online
      </h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-3">
      <div class="text-left">
        <form (ngSubmit)="deviceLink(formData.value)" [formGroup]="formData">
          <div class="row">
            <div class="form-group row col-md-12">
              <label class="col-md-2 col-form-label required_label"
                >{{'Người nhận' | translate}}</label
              >
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  formControlName="customer_name"
                  [ngClass]="{'is-invalid': submitted && form.customer_name.errors}"
                  disabled
                />
              </div>
            </div>
  
            <div class="form-group row col-md-12">
              <label class="col-md-2 col-form-label required_label"
                >{{'Số điện thoại' | translate}}</label
              >
              <div class="col-md-10">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  formControlName="phone"
                  [ngClass]="{'is-invalid': submitted && form.phone.errors}"
                />
              </div>
            </div>
            <div class="form-group row col-md-12">
              <label class="col-md-2 col-form-label required_label"
                >{{'Địa chỉ' | translate}}</label
              >
              <div class="col-md-10">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  formControlName="address"
                  [ngClass]="{'is-invalid': submitted && form.address.errors}"
                />
              </div>
            </div>
          </div>
  
          <div class="text-center">
            <button
              type="button"
              class="btn btn-secondary btn-lg mr-3"
              style="width: 150px;"
              (click)="modal('close click')"
            >
              {{'Đóng' | translate}}
            </button>
            <!-- <button
                type="submit"
                class="btn btn-success btn-lg"
                style="width: 150px;"
              >
                {{'Lưu' | translate}}
              </button> -->
          </div>
        </form>
      </div>
    </div>
  </ng-template>
  
  <ng-template #contentKeyword let-modalHis="close">
    <div class="modal-header">
      <h5 class="modal-title">List keyword of "{{selectedIncident.name}}"</h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modalHis('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-3">
      <div class="mb-3" *ngIf="formNewKeyword">
        <h5 class="font-size-14">Add New Keyword</h5>
        <form
          [formGroup]="formNewKeyword"
          (submit)="onSubmitFormKeyword(2)"
          class="d-flex"
        >
          <input
            type="text"
            class="form-control form-control-sm"
            style="width: 250px;"
            formControlName="name"
          />
          <button class="btn btn-primary btn-sm ml-3" style="width: 150px;">
            Add
          </button>
        </form>
      </div>
      <div class="table-responsive">
        <table
          id="basic-datatable"
          class="table table-bordered dt-responsive nowrap no-footer dtr-inline"
        >
          <thead>
            <tr>
              <th width="50">#</th>
              <th>Name</th>
              <!-- <th>Thời gian</th> -->
              <th width="170"></th>
            </tr>
          </thead>
          <tbody *ngFor="let data of dataKeyword;let i=index">
            <tr>
              <td class="table-column">{{i+1}}</td>
              <td class="table-column">
                <ng-container
                  *ngIf="selectedKeyword?.id == data.id && formEditKeyword; else normal"
                >
                  <form
                    [formGroup]="formEditKeyword"
                    (submit)="onSubmitFormKeyword(1)"
                  >
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      formControlName="name"
                    />
                  </form>
                </ng-container>
                <ng-template #normal>{{data.name}}</ng-template>
              </td>
              <!-- <td class=" table-column">{{data.created_at*1000 | date:'dd/MM/yyyy HH:mm'}}</td> -->
              <td class="table-column">
                <div class="d-flex" style="gap: 10px;">
                  <a
                    class="btn btn-sm btn-success"
                    href="javascript: void(0);"
                    (click)="editKeyword(data,i)"
                  >
                    <i class="mdi mdi-account-edit"></i>{{'FORM.Edit' |
                    translate}}</a
                  >
                  <a
                    class="text-white btn btn-sm btn-danger"
                    (click)="onDeleteKeyword(data.id)"
                    ><i class="mdi mdi-delete"></i>{{'FORM.Delete' |
                    translate}}</a
                  >
                </div>
                <!-- <button class="btn" (click)="editKeyword(data,i)">Sửa</button>
                                  <button>Xoá</button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-template>
  <ng-template #contentQR role="document" let-modal="close">
    <div class="modal-header">
      <h5 class="modal-title">
        Hiển thị QR
      </h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-3">
      <div class="text-left">
        <form [formGroup]="formData">
          <div class="row">
            <div class="form-group row col-md-12">
              <label class="col-md-2 col-form-label required_label"
                >{{'Serial' | translate}}</label
              >
              <div class="col-md-10">
                <ng-select
                  [items]="listDevicesQr"
                  bindLabel="serial_number"
                  bindValue="serial_number"
                  formControlName="serial_number"
                  (change)="onDeviceChange($event)"
                  placeholder="{{ 'Chọn thiết bị' | translate }}"
                  [ngClass]="{'is-invalid': submitted && form.serial_number.errors}"
                  [compareWith]="compareFn"
  
                >
                </ng-select>
              </div>
            </div>
            <div class="form-group row col-md-12">
              <div class="col-md-12 d-block justify-content-center">
                <div class="d-flex justify-content-center">
                  <div
                    class="borderQR d-flex flex-column align-items-center"
                    *ngIf="flagDownQr"
                    #qrContainer
                  >
                    <div class="qr-with-text canvas-container">
                      <qr-code
                        class="custom-qr-code"
                        [value]="qrCodeValue"
                        [size]="400"
                        [errorCorrectionLevel]="'M'"
                      >
                      </qr-code>
                      <div class="text-center user-info" *ngIf="flagDownQr">
                        <h3 class="font-weight-bold">
                          {{dataUser.terminal_bank_acc_name}}
                        </h3>
                        <h3 class="font-weight-bold">
                          {{dataUser.terminal_bank_acc}}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center mt-3">
                  <div class="col-12 col-md-3 p-0">
                    <button class="btn-viettel col" (click)="downloadQRCode()">
                      <span class="m-auto">Tải xuống mã QR</span>
                    </button>
                    <button class="btn-viettel col mt-2" (click)="printQRCode()">
                      In QR Code
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="text-center">
            <button
              type="button"
              class="btn btn-secondary btn-lg mr-3"
              style="width: 150px;"
              (click)="modal('close click')"
            >
              {{'Đóng' | translate}}
            </button>
            <!-- <button
              type="submit"
              class="btn btn-success btn-lg"
              style="width: 150px;"
            >
              {{'Lưu' | translate}}
            </button> -->
            <!-- <button type="button" class="btn btn-secondary btn-sm" style="width: 150px"
                          (click)="modal('close click')">{{'FORM.Close' | translate}}</button>
                      <button type="button" class="btn btn-primary btn-sm ml-3" style="width: 150px"
                          (click)="updateFormNote()">{{'FORM.Save' | translate}}</button> -->
          </div>
        </form>
      </div>
    </div>
  </ng-template>
  