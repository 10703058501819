import { Component, OnInit } from '@angular/core';
import { Inject, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { EventService } from 'src/app/core/services/event.service';
import { FormBuilder, FormGroup, MinLengthValidator, Validators } from '@angular/forms';
import { NgbModal, NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { SafeUrl } from '@angular/platform-browser';
import { CurrencyPipe } from '@angular/common';
@Component({
  selector: 'app-footer-Cs',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private router: Router,
    private apiService: ApiService,
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    private eventService: EventService,
    private calendar: NgbCalendar,
    private _lightbox: Lightbox,
    private _lightboxConfig: LightboxConfig,
    public translateService: TranslateService
  ) { }
  @Output() CsMenuButtonClicked = new EventEmitter();
  qrCodeValue: string;
  formData: FormGroup
  totalCount = 0
  pageSize = 10
  pageIndex = 1
  submitted: boolean

  tableName2 = 'workspaces/device-and-bankaccount'
  listData = []
  list_serial_number = [];
  list_bankaccount = []
  get form() {
    return this.formData.controls
  }
  ngOnInit(): void {
  }
  toggleCsMenu(event: any) {
    event.preventDefault();
    this.CsMenuButtonClicked.emit();
  }
  openModal(content: any, d?) {
    this.fetchData1()
    this.qrCodeValue = ''
    this.formData = this.makeForm(d)
    this.modalService.open(content, { centered: true, scrollable: true, size: 'xl' });
  }
  makeForm(d?) {
    let data = d ? d : <any>{}
    return this.formBuilder.group({
      id: [data?.id],
      user_id: JSON.parse(localStorage.getItem('currentUser'))['id'],
      token: JSON.parse(localStorage.getItem('currentUser'))['user_token'],
      device_id: [data.device_id || -1 || [Validators.required]],
      bankaccount_id: [data.bankaccount_id || -1],
      mid: [data.mid || 0],
      amount: [data.amount, [Validators.required]],
      qr_string: [""],
      bill_number: [""]
    })
  }
  fetchData1() {
    this.eventService.setLoading(true)
    this.apiService.getList(this.tableName2 +
      `?status=1`
    ).subscribe({
      next: (res) => {
        this.listData = res
        this.listData.forEach((item) => {
          if (item['bankaccount_id'] && item['device_id']) {
            this.list_serial_number.push(item['device_id']);
            this.list_bankaccount.push(item['bankaccount_id'])
          }
        })

        this.totalCount = res.length
        this.eventService.setLoading(false)
      }
    })
  }
  addQR = <any>{

  };

  bill_number: string
  midQR: string;
  amountQR: number;
  getSerialNum(id) {
    let selectedItem = this.listData.filter(item => item.id == id)[0]
    return selectedItem?.serial_number
  }
 
}
