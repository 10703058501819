import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'leaflet';
import { EventService } from 'src/app/core/services/event.service';
import { ApiService } from 'src/app/shared/services/api.service';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { error } from 'jquery';

@Component({
  selector: 'app-vtp-pending',
  templateUrl: './vtp-pending.component.html',
  styleUrls: ['./vtp-pending.component.scss']
})
export class VtpPendingComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    private eventService: EventService,
    private translateService: TranslateService,
    private router: Router,

    // private modalService: NzModalService,
    // private notification: NzNotificationService
  ) { }
  searchTerm
  listData = []
  selectedFile: File | null = null;
  pageSize = 10
  pageIndex = 1
  params = ''
  searching
  totalCount = 2
  tableName = 'admin/customer-orders'
  role = JSON.parse(localStorage.getItem('currentUser'))['role']
  submitted: boolean
  formData: FormGroup
  listModel = ['MD712', 'MD716', 'MD760', 'Y50', 'Y56', 'F91']
  listVersion = ['Paybox', 'Soundbox']
  listStatus = [
    { value: 'cancel', label: 'Huỷ' },
    { value: 'pending_payment', label: 'Chờ thanh toán' },
    { value: 'processing', label: 'Đang đợi xử lý' },
    { value: 'complete', label: 'Hoàn thành' },
    { value: 'ready_to_pick', label: 'Sẵn sàng để lấy hàng' },
    { value: 'picking', label: 'Đang lấy hàng' },
    { value: 'delivering', label: 'Đang giao hàng' },
    { value: 'transporting', label: 'Đang vận chuyển' },
    { value: 'money_collect_picking', label: 'Thu tiền khi lấy hàng' },
    { value: 'picked', label: 'Đã lấy hàng' },
    { value: 'storing', label: 'Đang phân loại' },
    { value: 'money_collect_delivering', label: 'Thu tiền khi giao hàng' },
    { value: 'delivered', label: 'Đã giao hàng' },
    { value: 'delivery_fail', label: 'Giao hàng thất bại' },
    { value: 'return_fail', label: 'Trả hàng thất bại' },
    { value: 'exception', label: 'Ngoại lệ' },
    { value: 'damage', label: 'Hư hỏng' },
    { value: 'lost', label: 'Mất' },
    { value: 'return', label: 'Trả hàng' },
    { value: 'waiting_to_return', label: 'Đang chờ trả hàng' },
    { value: 'return_transporting', label: 'Đang vận chuyển trả hàng' },
    { value: 'return_sorting', label: 'Đang phân loại trả hàng' },
    { value: 'returning', label: 'Đang trả hàng' },
    { value: 'refunded', label: 'Đã hoàn tiền' },
    { value: 'returned', label: 'Đã trả hàng' },
    { value: 'confirmed', label: 'Đã xác nhận' }
  ];
  qrValues = [
    { user_id: 1, token: 'token1', serial_number: 'serial1' },
    { user_id: 2, token: 'token2', serial_number: 'serial2' },
    { user_id: 3, token: 'token3', serial_number: 'serial3' },
  ];
  breadCrumbItems
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  listDevices
  listDevicesFilter = [{ value: '4G', label: 'Loa 4G' },
  { value: 'WIFI', label: 'Loa Wifi' }]
  listTypeOrder = [{ value: '1', label: 'Đã liên kết thiết bị' },
  { value: '0', label: 'Chưa liên kết thiết bị' }]
  listDevicesQr
  dataUser
  get form() {
    return this.formData.controls
  }
  filter = {
    type_id: '',
    phone: '',
    pair_device: '',
    ghn: 1,
    shipping_unit: "VTP",
    customer_name: '',
    terminal_bank_acc: '',
    bankAccountName: '',
    deliveryMethod: 'shipper',
    startDay: null,
    endDay: null,
    status: '',
    device_type: '',
    referrer: '',
    recipientPhone: '',
    serial_number: ''
  }

  ngOnInit() {
    this.breadCrumbItems = [{ label: 'PAGE.HOME' }, { label: 'PAGE.REPORT.IncidentType', active: true }];
    this.submitFilter()

    this.fetchDataDevive('')
    this.range.valueChanges.subscribe((rangeValues) => {
      const formatDate = (date: Date | null) => {
        if (!date) return null;
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
      this.filter.startDay = formatDate(rangeValues.start);
      this.filter.endDay = formatDate(rangeValues.end);
    });

  }
  onSearchChange(searchTerm) {
    this.fetchDataDevive(searchTerm?.term)
  }
  fetchDataDevive(device) {
    this.apiService.getList('device-stock?serial_number=' + device).subscribe({
      next: (res) => {
        this.listDevices = res.metadata['deviceStocks'] ? res.metadata['deviceStocks'] : [];
      }
    })
  }
  downloadQRCode() {
    const elementToDownload = document.querySelector('.qr-with-text').parentElement;

    if (elementToDownload) {
      html2canvas(elementToDownload as HTMLElement).then((canvas) => {
        const qrUrl = canvas.toDataURL('image/png');

        const link = document.createElement('a');
        link.href = qrUrl;
        link.download = `QR_Code_${this.deviceName}.png`;
        link.click();
      });
    } else {
      console.error('Không tìm thấy phần tử.');
    }
  }
  printQRCode() {
    // Lấy phần tử cần in
    const elementToPrint = document.querySelector('.qr-with-text')?.parentElement;

    if (!elementToPrint) {
      console.error('Không tìm thấy phần tử để in.');
      return;
    }

    html2canvas(elementToPrint).then((canvas) => {
      const qrUrl = canvas.toDataURL('image/png');

      const printWindow = window.open('', '_blank');
      printWindow?.document.write(`
        <html>
          <head>
            <title>In QR Code</title>
            <style>
              @media print {
                body {
                  margin: 0;
                  padding: 0;
                }
                .print-container {
                  text-align: center;
                  width: 100%;
                  max-width: 450px; 
                }
                img {
                  width: 100%; 
                  height: auto;
                }
                @page {
                  margin: 0; /* Bỏ margin của trang */
                }
              }
            </style>
          </head>
          <body>
            <div class="print-container">
              <img src="${qrUrl}" />
              <!-- Bỏ phần tên ở dưới -->
            </div>
          </body>
        </html>
      `);
      printWindow?.document.close();
      printWindow?.addEventListener('load', () => {
        printWindow?.print();
        printWindow?.close();
      });
    });
  }
  logDateRange(start: Date | null, end: Date | null) {
    console.log('Start date:', start);
    console.log('End date:', end);
  }
  onChangePage(e) {
    this.pageIndex = e
    this.fetchData()
  }
  handleOrder(data) {
    Swal.fire({
      title: this.translateService.instant('FORM.Warning'),
      text: this.translateService.instant('Bạn có chắc chắn muốn tạo đơn hàng?'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#ff3d60',
      confirmButtonText: this.translateService.instant('FORM.WarningConfirm'),
      cancelButtonText: this.translateService.instant('FORM.Cancel')
    }).then((result) => {
      if (result.isConfirmed) {
        this.apiService.postItem(`customer-orders-v2/` + data.id, {}).subscribe({
          next: () => {
            this.apiService.showToastMobile(
              this.translateService.instant('success'),
              this.translateService.instant('Tạo đơn hàng thành công')
            );
            this.fetchData();
          },
          error: (err) => {
            if (err) {
              this.apiService.showToastMobile(
                'error',
                this.translateService.instant(err)
              );
            } else {
              this.apiService.showToastMobile(
                'error',
                this.translateService.instant("Tạo thất bại")
              );
            }
          },
        });
      }
    });
  }
  onLink(data, contentLink) {
    this.getCountDevice(data.id)
    this.formData = this.makeForm(data)
    this.modalService.open(contentLink, { centered: true, scrollable: true, size: 'xl' });
  }
  deviceLink(data) {
    const dataPost = {
      serialNumbers: data.serial_number,
      customerOrderId: data.id,
      workspaceId: data.workspace_id

    }
    this.apiService.postItem('cs/device-subscription', dataPost).subscribe({
      next: res => {
        this.apiService.showToastMobile(this.translateService.instant('success'), this.translateService.instant('Liên kết thành công'));
        this.modalService.dismissAll()
        this.eventService.setLoading(false);
        this.fetchData();

      },
      error: e => {
        if (e) {
          this.apiService.showToastMobile('error', this.translateService.instant(e));
          this.eventService.setLoading(false);
        } else {
          this.apiService.showToastMobile('error', this.translateService.instant('Liên kết thất bại'));
          this.eventService.setLoading(false);
        }
      }
    });
  }
  clearFilter() {
    this.params = ''
    this.filter.serial_number = ''
    this.filter.type_id = ''
    this.filter.ghn = 1
    this.filter.terminal_bank_acc = ''
    this.filter.bankAccountName = ''
    this.filter.deliveryMethod = 'shipper'
    this.filter.startDay = null
    this.filter.endDay = null
    this.filter.customer_name = ''
    this.filter.status = ''
    this.filter.referrer = '',
      this.filter.device_type = '',
      this.filter.pair_device = '',
      this.filter.phone = ''
    this.filter.recipientPhone = ''
    this.range.reset();
    this.pageIndex = 1
    this.submitFilter()
  }

  submitFilter() {
    this.params = ''
    if (this.filter.status !== '') {
      this.filter.ghn = 0;
    }
    Object.keys(this.filter).forEach(key => {
      if (this.filter[key]) {
        this.params += `&${key}=${this.filter[key]}`
      }
    })
    if (this.params) {
      this.pageIndex = 1
      this.searching = true
      this.fetchData()
    }
  }

  fetchData() {
    this.eventService.setLoading(true)
    this.apiService.getList(this.tableName +
      `?page=${this.pageIndex}&limit=${this.pageSize}${this.params}`
    ).subscribe({
      next: (res) => {
        this.listData = res.metadata['listOrder'] ? res.metadata['listOrder'] : [];
        this.totalCount = res.metadata.pagination.totalCount ? res.metadata.pagination.totalCount : '';
        this.eventService.setLoading(false)
      }
    })
  }
  countDevice
  getCountDevice(id) {
    this.apiService.getList('cs/device-subscription?customerOrderId=' + id
    ).subscribe({
      next: (res) => {
        this.formData.patchValue({
          quantity: res.metadata.missingDevices + res.metadata.attachedDevices
        });
      }
    })
  }
  excelData: any;

  onFileChange(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.selectedFile = file;
    }
  }

  // Gửi file lên server
  uploadFile() {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile, this.selectedFile.name);

      this.http.post('device-stock', formData).subscribe(
        response => {
          console.log('File uploaded successfully', response);
        },
        error => {
          console.error('Error uploading file', error);
        }
      );
    } else {
      console.error('No file selected');
    }
  }
  addLang() {
    const language = this.form.language as FormArray
    let lang = this.makePrice()
    language.push(lang)
  }

  removeLang(i) {
    const language = this.form.language as FormArray
    language.removeAt(i)
  }

  openModal(content: any) {
    this.submitted = false
    this.formData = this.makeForm({ language: [{}] })
    let a = this.modalService.open(content, { centered: true, scrollable: true, size: 'lg' });
  }
  deviceName
  onEdit(data, content) {
    this.listDevicesQr = data.serial_number
    this.dataUser = data
    this.formData = this.makeForm(data)
    console.log(this.formData)
    this.onDeviceChange(this.listDevicesQr[0])
    this.modalService.open(content, { centered: true, scrollable: true, size: 'xl' });
  }
  onDeviceChange(selectedDevice: any) {
    this.viewQRcode(selectedDevice);
    this.deviceName = selectedDevice

  }
  compareFn(item1: any, item2: any): boolean {
    return item1.serial_number === item2.serial_number;
  }
  selectedIncident = null
  selectedKeyword = null
  exportExel() {
    this.eventService.setLoading(true);

    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const url = 'admin/customer-orders?&mode=export' + this.params;

    this.http.get(url, { headers, responseType: 'blob' }).subscribe({
      next: (res: Blob) => {
        this.eventService.setLoading(false);

        // Tạo và lưu file sử dụng FileSaver
        saveAs(res, 'Bao_cao.xlsx');
      },
      error: (e) => {
        console.error('Lỗi API:', e);
        this.eventService.setLoading(false);
        alert('Đã xảy ra lỗi khi gọi API.');
      }
    });
  }
  exportPDF() {
    this.eventService.setLoading(true);
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    this.http.get('cs/gen-list-qr', { headers, responseType: 'blob' }).subscribe({
      next: (response: Blob) => {
        this.eventService.setLoading(false);

        const blobUrl = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = 'Danh_sach_ma_QR.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobUrl);
      },
      error: e => {
        if (e) {
          this.apiService.showToastMobile('error', this.translateService.instant(e))
        } else {
          this.apiService.showToastMobile('error', this.translateService.instant('Xuất file lỗi'))
        }
        this.eventService.setLoading(false);
      }
    });
  }
  qrCodeValue: string;
  flagDownQr = false
  viewQRcode(dataQR: any): Promise<string> {
    return new Promise((resolve, reject) => {
      console.log(dataQR);
      this.qrCodeValue = '';
      this.flagDownQr = false;
      const data = {
        user_id: dataQR.user_id ? dataQR.user_id : this.dataUser.user_id,
        token: dataQR.token ? dataQR.user_id : this.dataUser.token,
        mid: dataQR.serial_number ? dataQR.serial_number : dataQR,
        qrcodeType: 11
      };

      this.apiService.apiPostToPaydee('api/mbpaybox/create-qr', data).subscribe({
        next: res => {
          this.qrCodeValue = res['data']['qr_string'] ? res['data']['qr_string'] : '';
          this.flagDownQr = true;
          this.apiService.showToastMobile(this.translateService.instant('success'), this.translateService.instant("Tạo QR thành công"));
          this.eventService.setLoading(false);
          resolve(this.qrCodeValue);
        },
        error: e => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          Toast.fire({
            icon: "warning",
            title: "Có lỗi xảy ra"
          });
          this.eventService.setLoading(false);
          reject(e);
        }
      });
    });
  }
  // viewQRcode(dataQR) {
  //   console.log(dataQR);
  //   this.qrCodeValue = ''
  //   this.flagDownQr = false
  //   const data = {
  //     user_id: dataQR.user_id,
  //     token: dataQR.token,
  //     mid: dataQR.serial_number,
  //     qrcodeType: 11
  //   }
  //   this.apiService.apiPostToPaydee('api/mbpaybox/create-qr' ,data).subscribe({
  //     next: res => {
  //       this.qrCodeValue = res['data']['qr_string'] ? res['data']['qr_string'] : '',
  //         this.flagDownQr = true
  //       this.apiService.showToastMobile(this.translateService.instant('success'), this.translateService.instant("Tạo QR thành công"))
  //       this.eventService.setLoading(false)
  //     },
  //     error: e => {
  //       const Toast = Swal.mixin({
  //         toast: true,
  //         position: "top-end",
  //         showConfirmButton: false,
  //         timer: 2000,
  //         timerProgressBar: true,
  //       });
  //       Toast.fire({
  //         icon: "warning",
  //         title: "Có lỗi xảy ra"
  //       });
  //       this.eventService.setLoading(false)
  //     }
  //   })
  // }
  makeFormKeyword(d) {
    let data = d ? d : <any>{}
    return this.formBuilder.group({
      id: [data.id],
      name: [data.name],
      incident_type_id: [data.incident_type_id]
    })
  }

  fetchKeyword() {
    this.dataKeyword = []
    this.eventService.setLoading(true)
    this.apiService.getList(`keyword?type_id=${this.selectedIncident.id}`).subscribe({
      next: res => {
        console.log(res)
        this.dataKeyword = [...res]
        this.eventService.setLoading(false)
      }
    })
  }

  dataKeyword = []

  formEditKeyword: FormGroup
  formNewKeyword: FormGroup
  editKeyword(data, index) {
    this.formEditKeyword = this.makeFormKeyword(data)
    this.selectedKeyword = data
  }

  onSubmitFormKeyword(type) {
    if (type == 1) {
      if (this.formEditKeyword.valid) {
        const value = this.formEditKeyword.value
        this.eventService.setLoading(true)
        this.apiService.editItem('keyword', value.id, value).subscribe({
          next: res => {
            this.selectedKeyword = null
            this.fetchKeyword()
            this.eventService.setLoading(false)

          }
        })
      }
    } else {
      if (this.formNewKeyword.valid) {
        const value = this.formNewKeyword.value
        this.eventService.setLoading(true)
        this.apiService.addItem('keyword', value).subscribe({
          next: res => {
            this.eventService.setLoading(false)
            this.formNewKeyword.reset({ incident_type_id: this.selectedIncident.id })
            this.fetchKeyword()
          }
        })
      }
    }
  }

  onOpenKeywordModal(content, incident) {
    this.selectedIncident = incident
    this.fetchKeyword()
    this.formNewKeyword = this.makeFormKeyword({ incident_type_id: this.selectedIncident.id })
    this.modalService.open(content, { centered: true, scrollable: true, size: 'xl' });
  }

  defaultPrices = [
    { condition: 1, price: 0, unit: '' },
    { condition: 2, price: 0, unit: '' }
  ]

  onDeleteKeyword(id) {
    if (id) {
      this.eventService.setLoading(true)
      this.apiService.deleteItem('keyword', id).subscribe(res => {

        Swal.fire(this.translateService.instant('FORM.Success'), this.translateService.instant('FORM.SuccessMessageDelete'), 'success')
          .then(
            res => {
              this.fetchKeyword();

            }
          )
        this.eventService.setLoading(false)

      }

      )
    }
  }

  onDelete(data) {
    Swal.fire({
      title: this.translateService.instant('FORM.Warning'),
      text: this.translateService.instant('FORM.WarningMessage'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#ff3d60',
      confirmButtonText: this.translateService.instant('FORM.WarningConfirm'),
      cancelButtonText: this.translateService.instant('FORM.Cancel')
    }).then(result => {
      if (result.value) {
        this.apiService.deleteItem(this.tableName, data).subscribe(res =>
          Swal.fire(this.translateService.instant('FORM.Success'), this.translateService.instant('FORM.SuccessMessageDelete'), 'success')
            .then(
              res => {
                this.fetchData();
              }
            )
        )
      }
    });
  }

  viewDetails(data: any) {
    this.router.navigate(['/VTP-Pending/detail-pending-vtp/', data.id]);
  }

  makeForm(d?) {
    let data = d ? d : <any>{}
    return this.formBuilder.group({
      id: [data.id],
      serial_number: [data.serial_number, [Validators.required]],
      quantity: [data.quantity - data.totalAttachedDevice],
      customer_name: [data.customer_name],
      workspace_id: [data.workspace_id],
      phone: [data.phone],
      address: [data.address],
      data: [`${data.customer_name || ''} - ${data.phone || ''} - ${data.address || ''}`],
    })
  }

  makePrices(data = []) {
    let arr = new FormArray([]);
    data.forEach(i => {

      arr.push(this.makePrice(i))
    })
    return arr;
  }

  makePrice(d?) {
    let data = d ? d : <any>{}
    return this.formBuilder.group({
      key: [data.key, [Validators.required]],
      // price: [data.price, [Validators.required, Validators.min(0)]],
      value: [data.value, [Validators.required]],
      // id: [data.id]
    })
  }

  uploadedImage

  saveData() {
    this.submitted = true
    if (this.formData.valid) {
      const value = this.formData.value
      value['status'] = value['status'] == true ? 1 : 0
      // value['prices'][0]['condition'] = 1
      // value['prices'][1]['condition'] = 2
      // value['price']= value['prices']
      this.eventService.setLoading(true)
      if (value.id) {
        this.apiService.editItem(this.tableName, value.id, value).subscribe({
          next: res => {
            this.submitted = false
            this.formData.reset()
            this.fetchData()
            this.modalService.dismissAll()
            this.apiService.showToast(this.translateService.instant('FORM.Success'), this.translateService.instant('FORM.SuccessMessage'), 'success')
            this.eventService.setLoading(false)
          },
          error: e => {
            this.apiService.showToast(this.translateService.instant('FORM.Error'), this.translateService.instant('FORM.ErrorMessage'), 'error')
            this.eventService.setLoading(false)
          }
        })
      } else {
        this.apiService.addItem(this.tableName, value).subscribe({
          next: res => {
            this.fetchData()
            this.apiService.showToast(this.translateService.instant('FORM.Success'), this.translateService.instant('FORM.SuccessMessageAdd'), 'success')
            this.eventService.setLoading(false)
            this.modalService.dismissAll()
          },
          error: e => {
            this.apiService.showToast(this.translateService.instant('FORM.Error'), this.translateService.instant('FORM.ErrorMessageAdd'), 'error')
            this.eventService.setLoading(false)
          }
        })
      }

    } else {
      console.log('invalid', this.formData)
      Object.values(this.formData.controls).forEach(control => {
        if (control.status == 'INVALID') {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  goToPreviousPage() {
    if (this.pageIndex > 1) {
      this.pageIndex--;
      this.fetchData();
    }
  }

  goToNextPage() {
    if (this.pageIndex < this.totalCount) {
      this.pageIndex++;
      this.fetchData();
    }
  }
}
