import { CommonService } from 'src/app/shared/services/common.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';

import { ApiService } from 'src/app/shared/services/api.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { User } from 'src/app/core/models/auth.models';

import { EventService } from '../../core/services/event.service';
import { LAYOUT_VERTICAL, LAYOUT_HORIZONTAL } from './layouts.model';

@Component({
  selector: 'app-layout-Cs',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutCsComponent implements OnInit {
  private currentUserSubject: BehaviorSubject<User>;

  // layout related config  
  layoutType: string;

  constructor(
    private apiService: ApiService,
    private commonService: CommonService,
    private router: Router,
    private http: HttpClient,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    public cookiesService: CookieService,
    private eventService: EventService) { }


  loading = this.eventService.loading$

  offLoading() {
    this.eventService.setLoading(false)
  }



  ngOnInit() {
    // default settings
    this.layoutType = LAYOUT_VERTICAL;
    // listen to event and change the layout, theme, etc
    this.eventService.subscribe('changeLayout', (layout) => {
      this.layoutType = layout;
    });
    // this.updateStatus()
    setInterval(() => this.updateStatus(), 300000);

  }

  /**
   * Check if the vertical layout is requested
   */
  isVerticalLayoutRequested() {
    return this.layoutType === LAYOUT_VERTICAL;
  }
  updateStatus() {
    const refreshToken = JSON.parse(localStorage.getItem('currentUser')).refreshToken;
    const clientId = JSON.parse(localStorage.getItem('currentUser')).id;
    if (this.commonService.flagLogin) {
      this.apiService.updateStatus('devices/update-status')
        .pipe(
          catchError((error) => {
            console.error('Lỗi khi cập nhật trạng thái:', error);
            return throwError(() => new Error(error.message || 'Lỗi không xác định.'));
          })
        )
        .subscribe({
          next: (res) => {
            console.log('Cập nhật trạng thái thành công:', res);
          },
          error: (err) => {
            console.error('Lỗi xảy ra:', err);
          }
        });
    } else {

      this.http.post<any>(
        'common/refresh-token',
        {},
        {
          headers: new HttpHeaders({
            'client-id': clientId ? clientId.toString() : '',
            'refresh-token': refreshToken || '',
          })
        }
      ).pipe(
        tap(user => {
          if (user && user.data) {
            const dt = { ...user.data };
            localStorage.setItem('currentUser', JSON.stringify(dt));
            this.currentUserSubject.next(dt);
          } else {
            throw new Error('Dữ liệu người dùng bị thiếu.');
          }
        }),
        switchMap(() => {
          if (this.apiService && this.apiService.updateStatus) {
            return this.apiService.updateStatus('devices/update-status');
          } else {
            throw new Error('Hàm updateStatus không tồn tại trong apiService.');
          }
        }),
        catchError((error) => {
          console.error('Lỗi khi refresh token hoặc cập nhật trạng thái:', error);
          return throwError(() => new Error(error.message || 'Lỗi không xác định.'));
        })
      ).subscribe({
        next: (res) => {
          // console.log('Cập nhật trạng thái sau khi refresh token thành công:', res);
        },
        error: (err) => {
          console.error('Lỗi xảy ra:', err);
        }
      });
    }
  }
  /**
   * Check if the horizontal layout is requested
   */
  isHorizontalLayoutRequested() {
    return this.layoutType === LAYOUT_HORIZONTAL;
  }
}
