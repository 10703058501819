import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Transaction } from './../dashboard/dashboard.model';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/core/services/event.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import html2canvas from 'html2canvas';


@Component({
  selector: 'app-detail-ghn-pending',
  templateUrl: './detail-ghn-pending.component.html',
  styleUrls: ['./detail-ghn-pending.component.scss']
})
export class DetailGhnPendingComponent implements OnInit {
  params = ''
  phone: Number
  filter = {
    // location_id: -1,
    status: -1,
    start_date: -1,
    end_date: -1
  }
  leader = ''
  data = <any>{}
  dataMember = <any>{}
  data1 = <any>{}
  data2 = <any>{}
  listData = []
  listData1 = []
  listDataQR = []
  listDevices = []
  id: number = 0;
  pageSize = 10
  pageIndex = 1
  totalCount = 2
  totalCount1 = 2
  listDataLog = []
  formData2: FormGroup
  formData: FormGroup
  tableName: string = 'workspaces/';
  tableName2 = 'devices/active'
  qrCodeValue: string;
  device_id_new
  reasonDelete: string = '';
  typePhone = [{ value: 'delivery_late', label: 'Đã gọi KH do giao chậm' }, { value: 'wrong_queue', label: 'Đã gọi KH nhầm luồng' }, { value: 'error_device', label: 'Đã gọi KH lỗi Loa' }, { value: 'orther', label: 'Khác' }]
  typeShip = [{ value: 'delivered', label: 'Hoàn thành' }, { value: 'cancel', label: 'Thất bại' }]
  typeUnit = [{ value: 'ghn', label: 'Giao hàng nhanh' }, { value: 'ahamove', label: 'Ahamove' }, { value: 'other', label: 'Khác' } ]
  role = JSON.parse(localStorage.getItem('currentUser')).role;
  makeForm(d?) {
    let data = d ? d : <any>{}
    return this.formBuilder.group({
      id: [data.id],
      model: [data.model, [Validators.required]],
      serial_number: [data.serial_number, [Validators.required]],
      serial_number_new: [data.serial_number_new],
      version: [data.version, [Validators.required]],
      userId: [data.userId, [Validators.required]],
      idWs: [data.idWs, [Validators.required]],
      typeUpdate: [data.typeUpdate],
      note: [data.note],
      orderCode: [data.orderCode],
      shipNote: [data.shipNote],


      // currency: [data.currency || 'VND'],
    })
  }
  // public person  
  constructor(
    public eventService: EventService,
    private apiService: ApiService,
    public activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.fetchData();
    this.fetchDataLog();
    this.fetchDataDevive()

  }

  ngAfterViewInit() {
    // Đảm bảo rằng qrContainer đã sẵn sàng
  }
  orderCode
  //Mã đơn hàng
  updateCodeShip() {
    this.apiService.changeStatus('customer-orders', this.data.id, { 'order_code': this.orderCode }).subscribe({
      next: (res: any) => {
        this.apiService.showToastMobile(this.translateService.instant('success'), this.translateService.instant('Cập nhật mã đơn thành công'))
        this.fetchData();
        this.modalService.dismissAll()
      },
      error: e => {
        if (e) {
          this.apiService.showToastMobile('error', this.translateService.instant(e))
          this.fetchData()
        } else {
          this.apiService.showToastMobile('error', this.translateService.instant('Cập nhật mã đơn thất bại'))
        }
        this.eventService.setLoading(false)
      }
    })
  }


  // Thêm tháng sử dụng
  onChangeTime(data) {
    Swal.fire({
      title: this.translateService.instant('FORM.Warning'),
      text: this.translateService.instant('Bạn có chắc chắn muốn thêm 1 tháng sử dụng?'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#ff3d60',
      confirmButtonText: this.translateService.instant('FORM.WarningConfirm'),
      cancelButtonText: this.translateService.instant('FORM.Cancel')
    }).then(result => {
      if (result.value) {
        console.log(data.id);
        // this.apiService.postItem('cs/customer-orders/cancel/' + this.id, {}).subscribe({
        //   next: (res: any) => {
        //     this.apiService.showToastMobile(this.translateService.instant('success'), this.translateService.instant('Hủy gói thành công'))
        //   },
        //   error: e => {
        //     if (e == 'Customer already has this package qr paybox') {
        //       this.apiService.showToastMobile('error', this.translateService.instant('Tài khoản đã sử dụng gói dịch vụ'))
        //     } else {
        //       this.apiService.showToastMobile('error', this.translateService.instant('Hủy gói thất bại'))
        //     }
        //     this.eventService.setLoading(false)
        //   }
        // })
      }
    });
  }
  fetchDataDevive() {
    this.apiService.getList('device-stock').subscribe({
      next: (res) => {
        this.listDevices = res.metadata['deviceStocks'] ? res.metadata['deviceStocks'] : [];
      }
    })
  }
  dataHistory
  onDetailHistory(data, content) {
    this.apiService.getList('ghn-order-callback?order_id=' + data.id).subscribe({
      next: (res) => {
        this.dataHistory = res.metadata ? res.metadata : [];
      }
    })
    this.modalService.open(content, { centered: true, scrollable: true, size: 'xl' });
  }
  onUpdate(data, content) {
    this.formData = this.makeForm(data)
    this.modalService.open(content, { centered: true, scrollable: true, size: 'xl' });
  }
  updateDone() {
    const data = {
      order_id: this.data.id,
      shipping_unit: this.formData.value.shipNote,
      order_code: this.formData.value.orderCode, 
      note: this.formData.value.orderCode, 
      status: this.formData.value.typeUpdate, 
    }
    
    this.apiService.postItem('customer-orders/create-order-unit', data).subscribe({
      next: (res: any) => {
        this.apiService.showToastMobile(this.translateService.instant('success'), this.translateService.instant('Cập nhật thành công'))
        this.modalService.dismissAll()
        this.fetchDataLog()
        this.fetchData()
      },
      error: e => {
        if (e) {
          this.apiService.showToastMobile('error', this.translateService.instant(e))
        } else {
          this.apiService.showToastMobile('error', this.translateService.instant('Cập nhật thất bại'))
        }
        this.eventService.setLoading(false)
      }
    })
  }
  updateOrder() {
    const data = {
      customerOrderId: this.data.id,
      action: this.formData.value.typeUpdate,
      note: this.formData.value.note,
    }
    this.apiService.postItem('cs-log', data).subscribe({
      next: (res: any) => {
        this.apiService.showToastMobile(this.translateService.instant('success'), this.translateService.instant('Cập nhật thành công'))
        this.modalService.dismissAll()
        this.fetchDataLog()
        this.fetchData()
      },
      error: e => {
        if (e) {
          this.apiService.showToastMobile('error', this.translateService.instant(e))
        } else {
          this.apiService.showToastMobile('error', this.translateService.instant('Cập nhật thất bại'))
        }
        this.eventService.setLoading(false)
      }
    })
  }
  countMember: number
  countTransaction: number
  onEdit(data, content) {
    console.log(data)
    this.deviceName = data.serial_number
    this.formData = this.makeForm(data)
    this.modalService.open(content, { centered: true, scrollable: true, size: 'xl' });
    this.viewQRcode(data)
  }
  onCancelOrder(data, content) {
    console.log(data)
    this.formData = this.makeForm(data)
    this.modalService.open(content, { centered: true, scrollable: true, size: 'xl' });
  }
  onChange(data, content) {
    console.log(data)
    this.formData = this.makeForm(data)
    this.modalService.open(content, { centered: true, scrollable: true, size: 'xl' });
  }
  listMember = []
  flagDownQr = false
  viewQRcode(dataQR) {
    console.log(dataQR);
    this.qrCodeValue = ''
    this.flagDownQr = false
    const data = {
      user_id: dataQR.user_id,
      token: dataQR.token,
      mid: dataQR.serial_number,
      qrcodeType: 11
    }
    this.apiService.apiPostToPaydee('api/mbpaybox/create-qr', data).subscribe({
      next: res => {
        this.qrCodeValue = res['data']['qr_string'] ? res['data']['qr_string'] : '',
          this.flagDownQr = true
        this.apiService.showToastMobile(this.translateService.instant('success'), this.translateService.instant("Tạo QR thành công"))
        this.eventService.setLoading(false)
      },
      error: e => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "warning",
          title: "Có lỗi xảy ra"
        });
        this.eventService.setLoading(false)
      }
    })
  }
  isWebView() {
    const userAgent = navigator.userAgent;

    return (userAgent.indexOf('FBAN') > -1) || (userAgent.indexOf('FBAV') > -1)
      || window.ReactNativeWebView
      || (userAgent.indexOf('wv') > -1)
      || (userAgent.indexOf('WebView') > -1)
      || (userAgent.indexOf('Android') > -1 && userAgent.indexOf('wv') > -1);
  }
  deviceName
  downloadQRCode() {
    const elementToDownload = document.querySelector('.qr-with-text').parentElement;

    if (elementToDownload) {
      html2canvas(elementToDownload as HTMLElement).then((canvas) => {
        const qrUrl = canvas.toDataURL('image/png');

        if (this.isWebView()) {
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'download', url: qrUrl }));
          }
        } else {
          const link = document.createElement('a');
          link.href = qrUrl;
          link.download = `QR_Code_${this.deviceName}.png`;
          link.click();
        }
      });
    } else {
      console.error('Không tìm thấy phần tử.');
    }
  }

  printQRCode() {
    // Lấy phần tử cần in
    const elementToPrint = document.querySelector('.qr-with-text')?.parentElement;

    if (!elementToPrint) {
      console.error('Không tìm thấy phần tử để in.');
      return;
    }

    html2canvas(elementToPrint).then((canvas) => {
      const qrUrl = canvas.toDataURL('image/png');

      const printWindow = window.open('', '_blank');
      printWindow?.document.write(`
        <html>
          <head>
            <title>In QR Code</title>
            <style>
              @media print {
                body {
                  margin: 0;
                  padding: 0;
                }
                .print-container {
                  text-align: center;
                  width: 100%;
                  max-width: 450px; 
                }
                img {
                  width: 100%; 
                  height: auto;
                }
                @page {
                  margin: 0; /* Bỏ margin của trang */
                }
              }
            </style>
          </head>
          <body>
            <div class="print-container">
              <img src="${qrUrl}" />
              <!-- Bỏ phần tên ở dưới -->
            </div>
          </body>
        </html>
      `);
      printWindow?.document.close();
      printWindow?.addEventListener('load', () => {
        printWindow?.print();
        printWindow?.close();
      });
    });
  }
  fetchData() {
    this.eventService.setLoading(true)
    this.apiService.getList('cs/customer-orders/' + this.id
    ).subscribe({
      next: (res) => {
        this.data = res.metadata
        this.listDataQR = this.data?.devices
        this.eventService.setLoading(false)
      }
    })
  }
  fullname = JSON.parse(localStorage.getItem('currentUser')).full_name

  fetchDataLog() {

    // this.eventService.setLoading(true)
    this.apiService.getList('cs/log' + `?orderId=${this.id}&page=${this.pageIndex}&limit=${this.pageSize}`
    ).subscribe({
      next: (res) => {
        // console.log(res);
        this.listDataLog = res.metadata.logOrders
        this.totalCount = res.metadata.pagination.totalCount ? res.metadata.pagination.totalCount : '';
        // this.eventService.setLoading(false)
      }
    })
  }
  handleCancel() {
    Swal.fire({
      title: this.translateService.instant('FORM.Warning'),
      text: this.translateService.instant('Bạn có chắc chắn hủy gói'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#ff3d60',
      confirmButtonText: this.translateService.instant('FORM.WarningConfirm'),
      cancelButtonText: this.translateService.instant('FORM.Cancel')
    }).then(result => {
      if (result.value) {
        this.apiService.postItem('cs/customer-orders/cancel/' + this.id, {}).subscribe({
          next: (res: any) => {
            this.apiService.showToastMobile(this.translateService.instant('success'), this.translateService.instant('Hủy gói thành công'))
          },
          error: e => {
            if (e == 'Customer already has this package qr paybox') {
              this.apiService.showToastMobile('error', this.translateService.instant('Tài khoản đã sử dụng gói dịch vụ'))
            } else {
              this.apiService.showToastMobile('error', this.translateService.instant('Hủy gói thất bại'))
            }
            this.eventService.setLoading(false)
          }
        })
      }
    });
  }
  searching = false

  submitFilter() {
    this.getByPhone()
  }

  phoneResultsName: string = '';
  phoneResultsAddress: string = '';
  filterNameTable = 'users/get-by-phone'
  getByPhone() {
    const requestData = {
      phone: this.phone
    }
    this.eventService.setLoading(true)
    this.apiService.getbyphone(this.filterNameTable, requestData
      // + `?page=${this.pageIndex}&limit=${this.pageSize}${params}`
    ).subscribe({
      next: (res) => {
        const dataPhone = res['data'];
        this.phoneResultsName = dataPhone['full_name'] ? dataPhone['full_name'] : '';
        this.phoneResultsAddress = dataPhone['address'] ? dataPhone['address'] : '';

        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "success",
          title: this.translateService.instant('FORM.Success')
        });
        this.searching = true
        this.eventService.setLoading(false)
      },
      error: e => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "error",
          title: this.translateService.instant('Số điện thoại không tồn tại')
        });
        this.eventService.setLoading(false)
        // this.apiService.showToast(this.translateService.instant('FORM.Error'), this.translateService.instant('FORM.userNotExist'), 'error')
        // this.eventService.setLoading(false)
      }
    })
  }
  makeForm2(d?) {
    let data = d ? d : <any>{}
    return this.formBuilder.group({
      // id: [data.id],
      // imei : [data.imei, [Validators.required]],
      // description: [data.description],
      // price: [data.price || 0, [Validators.required]],
      // model : [data.model, [Validators.required]],
      // image_url: [data.image_url, [Validators.required]],
      device_id: [data.device_id, [Validators.required]],
      bankaccount_id: [data.bankaccount_id, [Validators.required]],
      // type: [data.type, [Validators.required]],
      // currency: [data.currency || 'VND'],
    })
  }

  makeFormMember(d?) {
    let data = d ? d : <any>{}
    return this.formBuilder.group({
      phone: [data.phone, [Validators.required]],
      type: [data.type, [Validators.required]],
      // currency: [data.currency || 'VND'],
      workspace_id: this.activatedRoute.snapshot.params['id'],
    })
  }

  allMoney: number = 0

  list_serial_number = []



  openModalLink(contentLink) {
    this.submitted = false
    this.formData2 = this.makeForm2({ language: [{}] })
    let a = this.modalService.open(contentLink, { centered: true, scrollable: true, size: 'lg' });
  }

  tableName3 = 'bankaccounts/active'
  list_id_account = []
  listDataBankAccount = []
  submitted: boolean
  tableName4 = 'workspaces/device-and-bankaccount'

  fetchDataBankAccount(params = '') {
    params = '&order=updated_at&direction=desc'
    this.eventService.setLoading(true)
    this.apiService.getList(this.tableName3 + `?page=${this.pageIndex}&limit=${this.pageSize}${params}`
    ).subscribe({
      next: (res) => {
        this.listDataBankAccount = res['bankaccounts'] ? res['bankaccounts'] : [];

        this.listDataBankAccount.forEach((item) => {
          if (item['id']) {
            this.list_id_account.push(item['id']);
          }

        }
        )

        this.totalCount = res.length
        // // this.totalCount = res.meta.tota
        this.eventService.setLoading(false)
      }
    })
  }
  deviceLink() {
    const data = this.formData.value
    const dataPost = {
      orderId: this.id,
      serialNumber: data.serial_number,
      serialNumberNew: data.serial_number_new,
    }
    this.apiService.postItem('cs/device-subscription/change-device', dataPost).subscribe({
      next: res => {
        this.apiService.showToastMobile(this.translateService.instant('success'), this.translateService.instant('Thay đổi thành công'));
        this.modalService.dismissAll()
        this.eventService.setLoading(false);
        this.fetchData();

      },
      error: e => {
        this.apiService.showToastMobile('error', this.translateService.instant('Thay đổi thất bại'));
        this.eventService.setLoading(false);
      }
    });
  }
  saveData2() {
    this.submitted = true
    if (this.formData2.valid) {
      const value = this.formData2.value
      // value['status'] = value['status'] == true ? 0 : 1
      // value['prices'][0]['condition'] = 1
      // value['prices'][1]['condition'] = 2
      // value['price']= value['prices']
      this.eventService.setLoading(true)
      if (value.id) {
        this.apiService.editItem(this.tableName4, value.id, value).subscribe({
          next: res => {
            this.submitted = false
            this.formData2.reset()
            this.fetchDataBankAccount()
            this.modalService.dismissAll()
            this.apiService.showToast(this.translateService.instant('FORM.Success'), this.translateService.instant('FORM.SuccessMessage'), 'success')
            this.eventService.setLoading(false)
          },
          error: e => {
            this.apiService.showToast(this.translateService.instant('FORM.Error'), this.translateService.instant('Thêm không thành công'), 'error')
            this.eventService.setLoading(false)
          }
        })
      } else {
        this.apiService.addItem(this.tableName4, value).subscribe({
          next: res => {
            this.fetchDataBankAccount()
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            Toast.fire({
              icon: "success",
              title: this.translateService.instant('FORM.Success')
            });

            this.eventService.setLoading(false)
            this.modalService.dismissAll()
          },
          error: e => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            Toast.fire({
              icon: "error",
              title: this.translateService.instant('Thêm không thành công')
            });
            this.eventService.setLoading(false)
            // this.apiService.showToast(this.translateService.instant('FORM.Error'), this.translateService.instant('FORM.userNotExist'), 'error')
            // this.eventService.setLoading(false)
          }
        })
      }

    } else {
      console.log('invalid', this.formData2)
      Object.values(this.formData2.controls).forEach(control => {
        if (control.status == 'INVALID') {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  get form() {
    return this.formDataMember.controls
  }

  formDataMember: FormGroup
  listType = ['Trưởng nhóm', 'Phó cửa hàng', 'Thành viên']
  tableNameMember = 'members'

  openModalMember(contentMember: any) {
    this.submitted = false
    this.formDataMember = this.makeFormMember({ language: [{}] })
    let a = this.modalService.open(contentMember, { centered: true, scrollable: true, size: 'lg' });
  }

  listDataMember = []

  fetchDataMember() {
    // params = '&order=updated_at&direction=desc'
    this.eventService.setLoading(true)
    this.apiService.getList(this.tableNameMember +
      `?page=${this.pageIndex}&limit=${this.pageSize}${this.params}`
    ).subscribe({
      next: (res) => {
        // this.listData = res.members
        this.listDataMember = res['members'] ? res['members'] : [];
        this.totalCount = res['pagination']['totalCount'] ? res['pagination']['totalCount'] : '';
        this.eventService.setLoading(false)
      }
    })
  }

  saveDataMember() {

    this.submitted = true
    if (this.formDataMember.valid) {
      const value = this.formDataMember.value
      // value['status'] = value['status'] == true ? 0 : 1
      // value['prices'][0]['condition'] = 1
      // value['prices'][1]['condition'] = 2
      // value['price']= value['prices']
      this.eventService.setLoading(true)
      if (value.id) {
        this.apiService.editItem(this.tableNameMember, value.id, value).subscribe({
          next: res => {
            this.submitted = false
            this.formDataMember.reset()
            this.fetchDataMember()
            this.modalService.dismissAll()
            this.apiService.showToast(this.translateService.instant('FORM.Success'), this.translateService.instant('FORM.SuccessMessage'), 'success')
            this.eventService.setLoading(false)
          },
          error: e => {
            this.apiService.showToast(this.translateService.instant('FORM.Error'), this.translateService.instant('Thêm không thành công'), 'error')
            this.eventService.setLoading(false)
          }
        })
      } else {
        this.apiService.addItem(this.tableNameMember, value).subscribe({
          next: res => {
            this.fetchDataMember()
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            Toast.fire({
              icon: "success",
              title: this.translateService.instant('FORM.Success')
            });
            window.location.reload();
            this.eventService.setLoading(false)
            this.modalService.dismissAll()
          },
          error: e => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            Toast.fire({
              icon: "error",
              title: this.translateService.instant('Thêm không thành công')
            });
            this.eventService.setLoading(false)
            // this.apiService.showToast(this.translateService.instant('FORM.Error'), this.translateService.instant('FORM.userNotExist'), 'error')
            // this.eventService.setLoading(false)
          }
        })
      }

    } else {
      console.log('invalid', this.formDataMember)
      Object.values(this.formDataMember.controls).forEach(control => {
        if (control.status == 'INVALID') {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  onDelete() {
    console.log();

    Swal.fire({
      text: this.translateService.instant('Bạn có chắc chắn muốn hủy đơn hàng?'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'red',
      cancelButtonColor: 'fff',
      confirmButtonText: this.translateService.instant('FORM.WarningConfirm'),
      cancelButtonText: this.translateService.instant('FORM.Cancel'),
      customClass: {
        icon: 'my-custom-icon-class'
      }
    }).then(result => {
      if (result.value) {
        const data = {
          order_id: this.formData.value.id,
          reason: this.reasonDelete
        }
        this.apiService.postItem("order-cancel", data).subscribe(
          res => {
            this.modalService.dismissAll()
            this.fetchData()
            this.apiService.showToastMobile(this.translateService.instant('success'), this.translateService.instant('Tạo yêu cầu hủy thành công'))
          },
          error => {
            if (error) {
              this.apiService.showToastMobile('error', this.translateService.instant(error))
            } else {
              this.apiService.showToastMobile('error', this.translateService.instant('Tạo thất bại'))
            }
          }
        );
        this.eventService.setLoading(false);
      }
    });

  }
}

