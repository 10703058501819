import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/auth.models';

@Injectable({ providedIn: 'root' })

export class AuthfakeauthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }
    getCurrentUser() {
        return JSON.parse(localStorage.getItem('currentUser'))
    }
    login(phone: string, password: string) {
        return this.http.post<any>(`common/login`, { phone, password })
            .pipe(map(user => {
                // console.log(user)
                // login successful if there's a jwt token in the response
                if (user && user.data) {
                    const dt = { ...user.data };
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(dt));
                    this.currentUserSubject.next(dt);
                }
                return user;
            }));
    }

    loginMobileByPhone(phone: string, password: string) {
        return this.http.post<any>(`login-by-phone`, { phone, password })
            .pipe(map(user => {
                // console.log(user)
                // login successful if there's a jwt token in the response
                if (user && user.data) {
                    const dt = { ...user.data };
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(dt));
                    this.currentUserSubject.next(dt);
                }
                return user;
            }));
    }

    loginMobile(loginToken) {


        return this.http.post<any>(`login-mobile`, { loginToken })
            .pipe(map(user => {
                if (user && user.data) {
                    const dt = { ...user.data };
                    localStorage.setItem('currentUser', JSON.stringify(dt));
                    this.currentUserSubject.next(dt);
                }
                return user;
            }));
    }
    handlerLink(loginToken) {
        return this.http.post<any>(`common/user-connection`, { loginToken })
            .pipe(map(user => {
                // console.log(user)
                // login successful if there's a jwt token in the response
                if (user && user.data) {
                    const dt = { ...user.data };
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(dt));
                    this.currentUserSubject.next(dt);
                }
                return user;
            }));
    }

    logout() {
        // const dataUser = this.getCurrentUser()
        // console.log(dataUser);
        // remove user from local storage to log user out

        // return this.http.delete<any>(`common/logout`, { loginToken })
        //     .pipe(map(user => {
        //         // console.log(user)
        //         // login successful if there's a jwt token in the response
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        //     }));
    }
    logoutMobile() {
        const dataUser = this.getCurrentUser();
        const headers = new HttpHeaders({
            'client-id': dataUser.id ? dataUser.id.toString() : '',
            'refresh-token': dataUser.refreshToken || '',
        });
    
        return this.http.delete<any>('common/logout', { headers }).subscribe({
          next: res => {
            localStorage.removeItem('currentUser');
            localStorage.clear();
            this.currentUserSubject.next(null);
          } 
        })    
    }
    
}
