import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';

import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private authfackservice: AuthfakeauthenticationService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // if (request.url.includes('partner.viettelpost.vn')) {
        //     request = request.clone({
        //         setHeaders: {}
        //     });
        //     return next.handle(request);
        // }


        if (environment.defaultauth === 'firebase') {
            const currentUser = this.authenticationService.currentUser();
            if (currentUser && currentUser.token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${currentUser.token}`,
                    }
                });
            }
        } else {
            if (!request.url.includes('http') && !request.url.includes('assets')) {
                request = request.clone({
                    url: environment.host + request.url
                });
            }
            const currentUser = this.authfackservice.getCurrentUser();
            const workSpace = localStorage.getItem('workspace');
            if (currentUser && currentUser.token) {
                let headers = {
                    Authorization: `Bearer ${currentUser.token}`
                };
                if (workSpace) {
                    headers['workspace'] = workSpace;
                }
                request = request.clone({
                    setHeaders: headers
                });
            }
        }
        return next.handle(request).pipe(
            catchError(err => {
                if (err === 'Forbidden' || err === 'Unauthorized' || err === 'Invalid token') {
                    setTimeout(() => {
                        if (window['ReactNativeWebView']) {
                            window['ReactNativeWebView'].postMessage(JSON.stringify({
                                type: 'GO_BACK',
                            }));
                            console.log('Message posted to React Native WebView');
                        } else {
                            console.warn('ReactNativeWebView không tồn tại. Sử dụng môi trường local để test.');
                            window['ReactNativeWebView'] = {
                                postMessage: (message) => {
                                    console.log('Message posted to React Native (mock):', message);
                                }
                            };
                            window['ReactNativeWebView'].postMessage(JSON.stringify({
                                type: 'GO_BACK',
                            }));
                        }
                        this.router.navigate(['/account/login']);

                    }, 2000);
                }
                return throwError(err);
            })
        );
    }
}
