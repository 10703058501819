import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexFill
} from "ng-apexcharts";
import { EventService } from 'src/app/core/services/event.service';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-dashboard-new',
  templateUrl: './dashboard-new.component.html',
  styleUrls: ['./dashboard-new.component.scss']
})
export class DashboardNewComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: any;
  public chartOptionsService: any;
  public chartOptionsQR: any;
  data: any
  revenueData: any
  QRData: any
  packageData: any
  role = JSON.parse(localStorage.getItem('currentUser')).role;


  ngOnInit(): void {
    this.eventService.setLoading(true)
    this.getDataDB()
    
  }

  constructor(
    private eventService: EventService,
    private apiService: ApiService,
    private translate: TranslateService,
    private datePipe: DatePipe
  ) {
  }
  getDataDB() {
    this.eventService.setLoading(true)
    this.apiService.getList('admin/dashboard').subscribe({
      next: res => {
        this.data = res.metadata
        this.revenueData = {
          data: res.metadata.revenueLast7d.map(item => item.data),
          category: res.metadata.revenueLast7d.map(item =>
            this.datePipe.transform(new Date(item.date), 'dd/MM')
          )
        };
        this.packageData = {
          data: [
            {
              name: "Gói 3M",
              data: res.metadata.revenueLast7dPackage.map(item => item.data_3m)
            },
            {
              name: "Gói 6M",
              data: res.metadata.revenueLast7dPackage.map(item => item.data_6m)
            },
            {
              name: "Gói 12M",
              data: res.metadata.revenueLast7dPackage.map(item => item.data_12m)
            }
          ],
          category: res.metadata.revenueLast7d.map(item =>
            this.datePipe.transform(new Date(item.date), 'dd/MM')
          )
        }
        this.QRData = {
          data: [
            {
              name: "Tổng giá trị giao dịch",
              type: "column",
              data: res.metadata.revenueOR7d.map(item => item.total_amount)
            },
            {
              name: "Tổng số giao dịch",
              type: "line",
              data: res.metadata.revenueOR7d.map(item => item.total_transactions)
            }
          ],
          category: res.metadata.revenueOR7d.map(item =>
            this.datePipe.transform(new Date(item.date), 'dd/MM')
          )
        }

        this.chartOptions = {
          series: [
            {
              name: "Doanh thu",
              data: this.revenueData.data
            }
          ],
          chart: {
            height: 200,
            type: "bar"
          },
          colors: ["#F4C29F"],
          legend: {
            show: false
          },
          grid: {
            show: false
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "50%",
              endingShape: "rounded",
              colors: {
                ranges: [
                  {
                    from: 0,
                    to: 10000000,
                    color: "#B31942"
                  }
                ]
              }
            }
          },
          dataLabels: {
            enabled: false,
            style: {
              fontSize: "12px",
              colors: ["#304758"]
            }
          },

          xaxis: {
            categories: this.revenueData.category,
          },
          yaxis: {
            axisBorder: {
              show: true
            },
            axisTicks: {
              show: true
            },
            labels: {
              show: true,
              formatter: function (val) {
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " VNĐ";
              }
            }
          },
          title: {
            text: "Doanh thu theo ngày",
            style: {
              fontSize: '14px',
              color: '#606060',
              fontWeight: '600',
              fontFamily: 'open sans, Helvetica, Arial, sans-serif'
            }
          }
        };
        this.chartOptionsService = {
          series: this.packageData.data,
          chart: {
            type: "bar",
            height: 470
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              endingShape: "rounded"
            }
          },
          title: {
            text: "Gói dịch vụ",
            style: {
              fontSize: '14px',
              color: '#606060',
              fontWeight: '600',
              fontFamily: 'open sans, Helvetica, Arial, sans-serif'
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"]
          },
          xaxis: {
            categories: this.packageData.category,
          },
          yaxis: {
            title: {
              text: "Gói dịch vụ"
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " gói";
              }
            }
          }
        };
        this.chartOptionsQR = {
          series: this.QRData.data,
          chart: {
            height: 470,
            type: "line"
          },
          legend: {
            show: false
          },
          grid: {
            show: false
          },
          stroke: {
            width: [0, 4]
          },
          title: {
            text: "Giao dịch QR",
            style: {
              fontSize: '14px',
              color: '#606060',
              fontWeight: '600',
              fontFamily: 'open sans, Helvetica, Arial, sans-serif'
            }
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1]
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "50%",
              endingShape: "rounded"
            }
          },
          labels: this.QRData.category,
          xaxis: {
            categories: this.QRData.category,
          },
          yaxis: [
            {
              title: {
                text: "Tổng giá trị giao dịch"
              },
              axisBorder: {
                show: true
              },
              axisTicks: {
                show: true
              },
              labels: {
                show: true,
                formatter: function (val) {
                  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " VNĐ";
                }
              }
            },
            {
              opposite: true,
              title: {
                text: "Tổng số giao dịch"
              }
            }
          ]
        };
        this.eventService.setLoading(false)
      },
      error: e => {
        this.eventService.setLoading(false)
        this.apiService.showToast(this.translate.instant('Lỗi dữ liệu')
          , this.translate.instant('Dữ liệu chưa được tải về'), 'error')
      }
    })
    this.eventService.setLoading(false)
  }

}
