import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vcc-done',
  templateUrl: './vcc-done.component.html',
  styleUrls: ['./vcc-done.component.scss']
})
export class VccDoneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
