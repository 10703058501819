import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from './shared/shared.module';

import { VerticalCsComponent } from './vertical/vertical.component';
import { HorizontalCsComponent } from './horizontal/horizontal.component';
import { LayoutCsComponent } from './layout/layout.component';


@NgModule({
  declarations: [VerticalCsComponent, HorizontalCsComponent, LayoutCsComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,

  ],
  exports: [VerticalCsComponent, HorizontalCsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LayoutCsModule { }
