import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-vertical-mobile',
  templateUrl: './vertical.component.html',
  styleUrls: ['./vertical.component.scss']
})
export class VerticalMobileComponent implements OnInit {
  url: string;
  mobileFooterFlag: boolean = true;
  
  constructor(
    @Inject(Router) private router: Router,
    private activatedRoute : ActivatedRoute
  ) {}

  ngOnInit(): void {
    
    document.body.setAttribute('data-sidebar', 'dark');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-layout');
    document.body.removeAttribute('data-topbar');
    document.body.classList.remove('auth-body-bg');
  
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
        this.updateFooterFlag(this.url);
      }
    });

    this.url = this.router.url;
    this.updateFooterFlag(this.url);
  }
  
  updateFooterFlag(url: string) {
    const noFooterRoutes = ['/detail-shop', '/create-device', '/device-detail', '/transactions' , '/payment'];
    this.mobileFooterFlag = !noFooterRoutes.some(route => url.includes(route));
    
    // if (url.includes('/viettel/menu')) {
    //   document.body.classList.remove('page-content');
    //   console.log(1);
      
    // } else {
    //   document.body.classList.add('page-content');
    // }
  }
  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    document.body.classList.toggle('sidebar-enable');
    document.body.classList.toggle('vertical-collpsed');

    if (window.screen.width <= 768) {
      document.body.classList.remove('vertical-collpsed');
    }
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }
}
