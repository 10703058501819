<div class="bottom-nav">
  <div class="bottom-nav-container">
    <button class="btn d-block p-1 px-2" routerLink="/cs/dashboard">
      <i class="ri-home-3-fill" style="font-size: large;"></i>
      <div class="font-size-12">
        Trang chủ
      </div>
    </button>
    <div class="bottom-nav-center rounded-circle" routerLink="/cs/create-qr">
      <div class="qrcode bottom-nav-center-icon"></div>
    </div>
    <button
      class="btn d-block p-2 px-3"
      id="vertical-menu-btn"
      routerLink="/cs/menu"
    >
      <i class="fa-solid fa-gear" style="font-size: large;"></i>
      <div class="font-size-12">
        Quản lý
      </div>
    </button>
  </div>
</div>
